import React, { useState } from "react";
import {
  IoCalendarOutline,
  IoNotificationsOutline,
  IoSend,
  IoTimeOutline,
  IoTimerOutline,
} from "react-icons/io5";
import PopupBg from "../../components/ui/modal/PopupBg";
import { getDteforAPI } from "../../utils/fonctions";
import { repportCours } from "../../API/fonctions/repportCours";

const ReportingCoursesPopup = ({ onClose, donnees }) => {
  const [heureDebut, setHeureDebut] = useState(donnees.heur_debut);
  const [heureFin, setHeureFin] = useState(donnees.heur_fin);
  const [date, setDate] = useState(getDteforAPI);
  // // console.log(donnees)

  const reportCours = (e) => {
    e.preventDefault();
    const body = {
      cours_id: donnees.id_modif,
      nouvelle_date: date,
      nouvelle_heure_debut: heureDebut,
      nouvelle_heure_fin: heureFin,
    };
    // // console.log(body);
    repportCours(body, onClose);
  };
  return (
    <PopupBg closeModal={onClose} opacity="transparent">
      <div className="popup__form stopping__courses__popup">
        <h2>Reporter le cours</h2>

        <div className="input__box">
          <div className="icon">
            <IoCalendarOutline />
          </div>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        <div className="input__box intervals">
          <div className="icon">
            <IoTimerOutline />
          </div>
          <input
            type="time"
            value={heureDebut}
            onChange={(e) => setHeureDebut(e.target.value)}
          />
          <span className="dash">-</span>
          <input
            type="time"
            value={heureFin}
            onChange={(e) => setHeureFin(e.target.value)}
          />
        </div>

        {/* <div className="input__box">
          <div className="icon"><IoNotificationsOutline /></div>
          <input type="text" className='message' placeholder="Ecrire les motifs du report (facultatif)..." />
        </div> */}

        <div className="buttons">
          <button className="cancel" onClick={onClose}>
            Fermer
          </button>
          <button className="send" onClick={(e) => reportCours(e)}>
            Envoyer
            <span className="icon">
              <IoSend />
            </span>
          </button>
        </div>
      </div>
    </PopupBg>
  );
};

export default ReportingCoursesPopup;
