import React, { useState } from "react";
import {
  IoCallOutline,
  IoLockOpenOutline,
  IoMailOutline,
  IoPersonOutline,
} from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../API/fonctions/login";
import ErrorBox from "../../components/ui/ErrorBox";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [codeErreur, setCodeErreur] = useState(null); // Pour stocker le code d'erreur
  const [isErreur, setIsErreur] = useState(false);
  const navigate = useNavigate();

  const logForm = (e) => {
    e.preventDefault();
    const body = {
      login: email,
      password: password,
    };
    login(body, navigate, setCodeErreur, setIsErreur);
    // console.log("code d'erreur", codeErreur);
    // console.log("isErruer ", isErreur);
  };

  return (
    <form onSubmit={logForm}>
      <div className="logo__container">
        <div className="logo">C</div>
        <div className="logo__text">Caly</div>
      </div>

      <h1>Connexion</h1>

      <p className="desc">
        Entrez votre numéro de téléphone et votre mot de passe pour accéder à
        votre espace d'administration Caly
      </p>
      {isErreur && <ErrorBox codeError={codeErreur} />}

      <div className="input__box">
        <div className="icon">
          <IoCallOutline />
        </div>
        <input
          type="text"
          placeholder="Numéro de téléphone"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="input__box">
        <div className="icon">
          <IoLockOpenOutline />
        </div>
        <input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <button className="send__button">Se connecter</button>
      {/* <Link to='/home' className='send__button'>Se connecter</Link> */}
    </form>
  );
};

export default LoginForm;
