import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import {formatRank} from "../../../../utils/fonctions";

// Composant pour afficher la moyenne générale et autres infos
const GradesSummary = ({ gradesSummary }) => (
    <View style={styles.summaryRow}>
        <View style={[styles.infoCell, styles.moyenneCell]}>
            <Text>Moyenne: <Text style={styles.infoText}>{gradesSummary.moyenne}</Text> </Text>
        </View>
        <View style={[styles.infoCell, styles.rangCell]}>
            <Text>Rang: <Text style={styles.infoText}>{formatRank(gradesSummary.rank)}</Text></Text>
        </View>
        <View style={[styles.infoCell, styles.retardsCell]}>
            <Text>Retards: <Text style={styles.infoText}>{gradesSummary.retardsHeures}</Text> heure(s) <Text style={styles.infoText}>{gradesSummary.retardsMinutes}</Text> minute(s)</Text>
        </View>
        <View style={[styles.infoCell, styles.absencesCell, { borderRightWidth: 0 }]}>
            <Text>Absences: <Text style={styles.infoText}>{gradesSummary.absences}</Text> dont <Text style={styles.infoText}>{gradesSummary.absencesJustifiees}</Text> non justifiée(s)</Text>
        </View>
    </View>
);

// Styles pour la ligne de moyenne générale
const styles = StyleSheet.create({
    summaryRow: {
        flexDirection: 'row',
        backgroundColor: '#bdbdbd',
        width: '100%',
        fontSize: 10
    },
    infoCell: {
        paddingVertical: 6,
        paddingLeft: 4,
    },
    moyenneCell: {
        width: '16%',
    },
    rangCell: {
        width: '15%',
    },
    retardsCell: {
        width: '35%',
    },
    absencesCell: {
        width: '29%',
    },
    infoText: {
        fontWeight: 'semibold'
    }
});

export default GradesSummary;
