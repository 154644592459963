//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken, uploadTokenPUT } from "./upload";


const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function modierCours(body,id_cours, onClose, setMessageErreur, refetchCours) {
    const endpoint = "cours/" + id_cours;


    const reponse = await uploadTokenPUT(endpoint, body, headers);

    if (reponse.ok == true) {
        onClose();
        refetchCours();
      } else {
        // console.log("erreur ", reponse)
        const errorDetails = await reponse.json();
        console.error("Erreur serveur:", reponse.status, errorDetails);
        // setIsErreur(true)
        setMessageErreur(errorDetails.message);
      }

}

export { modierCours };
