import React, { useEffect, useRef, useState } from "react";
import isEqual from "lodash/isEqual";

const DropdownList = ({ icon, items, setValue, value, isLoading = false }) => {

  const prevListRef = useRef();
  const [id, setId] = useState();
    useEffect(() => {
      if (value) {
        setId(value);
    } else {
      // setId(items[0]?.id);
      if (!isEqual(prevListRef.current, items)) {
        setValue(items[0]?.id)
      }
    }
  },[])


  const handleChange = (e) => {
    const selectedId = e.target?.value;
    setId(selectedId);
    setValue(selectedId);
  };

  return (
    <div className="input__box">
      <div className="icon">{icon}</div>

      <select name="matiere" id="matiere" value={id} onChange={handleChange}>
        {isLoading ? <option>Chargement ...</option> : items?.map((item) => (
          <option key={item?.id} value={item?.id}>
            {item?.content}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownList;
