import React, { useState, useContext } from "react";
import {
  IoArrowBack,
  IoArrowForward,
  IoChevronBackOutline,
  IoChevronForwardOutline,
  IoPerson,
} from "react-icons/io5";
import UserBox from "../../components/user/UserBox";
import { ClassContext } from "../../contexts/ClassContext";
import { GetPaiementClasse } from "../../API/react_query/react_query";
import Loading from "../../layouts/Loading";
//import { formatNumber } from "chart.js/helpers";
import { formatPhoneNumber, transformMoonth } from "../../utils/fonctions";
import { formatDate } from "../../utils/dateConversion";

const PaymentsList = () => {
  const [switched, setSwitched] = useState(false);
  const months = [
    "",
    "Octobre",
    "Novembre",
    "Décembre",
    "Janvier",
    "Fèvrier",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
  ];

  let [monthIndex, setMonthIndex] = useState(2);

  const prev = () => {
    monthIndex > 1 ? setMonthIndex((prev) => prev - 1) : setMonthIndex(10);
  };

  const next = () => {
    monthIndex < 10 ? setMonthIndex((prev) => prev + 1) : setMonthIndex(1);
  };
  // // console.log(monthIndex);
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);
  // // console.log("le mois selectionner :", transformMoonth(monthIndex));

  const {
    isError: isErrorPaie,
    isLoading: isLoadingPaie,
    error: errorPaie,
    refetch: refetchMatPaie,
    isFetching: isFetchingPaie,
    data: dataPaie,
  } = GetPaiementClasse(currentClass, transformMoonth(monthIndex), anneeScol);
  // // console.log("list des paimement d'une classe ", dataPaie);

  const switchClass = () => {
    if (switched) return "main-content with-cols payments switcher switched";
    else return "main-content with-cols payments switcher";
  };

  return (
    <div className={switchClass()}>
      {isLoadingPaie || isFetchingPaie ? (
        <div className="part first-page">
          <h2>
            Payés ({dataPaie ? dataPaie.paiements.length : 0})
            <div className="switch-btn" onClick={() => setSwitched(true)}>
              <span className="text">Non payés</span>
              <span className="icon">
                <IoArrowForward />
              </span>
            </div>
          </h2>
          <Loading size="medium" />
        </div>
      ) : (
        <div className="part first-page">
          <h2>
            Payés ({dataPaie ? dataPaie.paiements.length : 0})
            <div className="switch-btn" onClick={() => setSwitched(true)}>
              <span className="text">Non payés</span>
              <span className="icon">
                <IoArrowForward />
              </span>
            </div>
          </h2>
          {dataPaie && dataPaie.paiements.length > 0 ? (
            dataPaie.paiements?.map((student, index) => (
              <div className="payment-box" key={index}>
                <UserBox
                  user={student.eleve}
                  subtitle={formatDate(student.date_paiement, false, true)}
                />
                <div className="matricuke">
                  {student.eleve.matricule}
                  <span className="icon">
                    <IoPerson />
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="vide medium">
              Les élèves de cette classe n'ont pas encore payés pour ce mois...
            </div>
          )}
        </div>
      )}

      {isLoadingPaie || isFetchingPaie ? (
        <div className="part second-page">
          <h2>
            <div className="switch-btn" onClick={() => setSwitched(false)}>
              <span className="icon">
                <IoArrowBack />
              </span>
              <span className="text">Payés</span>
            </div>
            Non payés ({dataPaie ? dataPaie.eleves_pas_paye.length : 0})
          </h2>
          <Loading size="medium" />
        </div>
      ) : (
        <div className="part second-page">
          <h2>
            <div className="switch-btn" onClick={() => setSwitched(false)}>
              <span className="icon">
                <IoArrowBack />
              </span>
              <span className="text">Payés</span>
            </div>
            Non payés ({dataPaie ? dataPaie.eleves_pas_paye.length : 0})
          </h2>
          {dataPaie && dataPaie.eleves_pas_paye.length > 0 ? (
            dataPaie &&
            dataPaie.eleves_pas_paye?.map((student, index) => (
              <div className="payment-box" key={index}>
                <UserBox
                  user={student.eleve}
                  subtitle={
                    student.eleve.telephone !== null
                      ? formatPhoneNumber(student.eleve.telephone)
                      : student.eleve.adresse
                  }
                />
                <div className="matricuke">
                  {student.eleve.matricule}
                  <span className="icon">
                    <IoPerson />
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className="vide medium">
              {/* <img src={Paid} alt="Non payes" /> */}
              <span>
                Tous les élèves de cette classe ont payé pour ce mois...
              </span>
            </div>
          )}
        </div>
      )}

      <div className="month-filter">
        <div className="icon" onClick={prev}>
          <IoChevronBackOutline />
        </div>
        <div className="month">{months[monthIndex]}</div>
        <div className="icon" onClick={next}>
          <IoChevronForwardOutline />
        </div>
      </div>
    </div>
  );
};

export default PaymentsList;
