import React from 'react'
import ToggleSelectBox from '../../components/form/ToggleSelectBox'
import { IoTimeOutline } from 'react-icons/io5'

const MissingCourseBox = ( {id, matiere, horaire, toggleFn}) => {
  return (
    <div className='missing__course__box__container'>
        <ToggleSelectBox id={id}  toggleFn={toggleFn}>
            <div className="missing__course__box">
                <div className="icon"><IoTimeOutline /></div>
                <div className="subtitle">{horaire}</div>
                <div className="title">{matiere}</div>
            </div>
        </ToggleSelectBox>
    </div>
  )
}

export default MissingCourseBox