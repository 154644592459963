import React from "react";
import { IoWarningOutline } from "react-icons/io5";
import PopupForm from "../../components/ui/modal/PopupForm";
import { deletEvaluation } from "../../API/fonctions/deletEvaluation";

const ConfimDelletEvaluation = ({ onClose, elementId, text, title }) => {
  const handleConfirmDelete = () => {
    // console.log(`Deleting subject with ID: ${elementId}`);
    // Here you would typically call your delete function
    // For now, we'll just close the popup
     deletEvaluation(elementId, onClose);
   // console.log("matiere supprimer avec succes",elementId)
    //onClose();
  };

  return (
    <PopupForm onClose={onClose} title={title} onSubmit={handleConfirmDelete}>
      <div className="confirm-delete-content">
        <IoWarningOutline className="warning-icon" />
        <p>{text}</p>
      </div>
    </PopupForm>
  );
};

export default ConfimDelletEvaluation;
