import React, { useRef } from 'react'
import { IoCalculatorOutline, IoCashOutline, IoPricetag } from 'react-icons/io5';
import PopupBg from '../../components/ui/modal/PopupBg';

const ComptaAddForm = ({ closeModal }) => {

  return (
    
    <PopupBg closeModal={closeModal} opacity="transparent">
        <div className="modal">
            <h2>Nouvelle dépense</h2>
            <p className="form-box">
                <div className="icon"><IoPricetag /></div>
                <input type="text" className='lib' placeholder='Libellé ...' />
            </p>
            <p className="form-box">
                <div className="icon"></div>
                <input type="text" className='desc' placeholder='Description ...' />
            </p>
            <p className="form-box amount">
                <div className="icon"><IoCashOutline /></div>
                <input type="text" placeholder='Montant ...' />
            </p>
            <p className="form-box">
                <div className="icon"></div>
                <input type="text" className='desc' placeholder='Numéro facture ...' />
            </p>
            <div className="more">
                <label htmlFor="date">
                    <span className="icon"><IoCalculatorOutline /></span>
                    Aujourd'hui
                </label>
                <input type="date" id="date" />
            </div>
            <div className="buttons">
                <button className="cancel" onClick={closeModal}>Fermer</button>
                <button className="edit">Ajouter</button>
            </div>
        </div>
    </PopupBg>
  )
}

export default ComptaAddForm