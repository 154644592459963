import React, { useState } from 'react'
import { IoSearchOutline } from 'react-icons/io5'

const SearchBar = ({ searchFn }) => {
  const [value, setValue] = useState('');

  const handleSearch = () => {
    value == '' ? searchFn('') : searchFn(value);
  }

  return (
    <div className="search">
      <div className="icon"><IoSearchOutline /></div>

      <input
        type="search"
        placeholder="Rechercher..."
        value={value}
        onChange={e => setValue(e.target.value)}
        // onFocus={onFocusFn}
        onKeyUp={handleSearch}
      />
    </div>
  )
}

export default SearchBar