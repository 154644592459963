import React from 'react'

const NumCard = ({ icon, number, title }) => {
  return (
    <div className="box">
      <div className="i">{icon}</div>
      <div className="more">
        <span className="num">{number}</span>
        <span className="lib">{title}</span>
      </div>
    </div>
  )
}

export default NumCard