import React from "react";
import { IoCheckmarkSharp } from "react-icons/io5";
import "../../assets/css/successcard.css";

const SuccessCard = ({ text }) => {
  return (
    <div className="success__card">
      <div className="icon">
        <IoCheckmarkSharp style={{ padding: 0, margin: 0, fontSize: 150 }} />
      </div>
      <div className="text">{text}</div>
    </div>
  );
};

export default SuccessCard;
