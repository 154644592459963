import React, {useContext} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {ClassContext} from "../contexts/ClassContext";
import {navebareFonction} from "../data/naveBarLiateViaIadUser";
import {RolesContext} from "../contexts/RolesContext";
import {
    IoAlarm,
    IoAlarmOutline,
    IoBagRemove,
    IoBagRemoveOutline,
    IoBookmark,
    IoBookmarkOutline,
    IoCalendar,
    IoCalendarOutline,
    IoDocumentText,
    IoDocumentTextOutline,
    IoHome,
    IoHomeOutline,
    IoPeople,
    IoPeopleOutline,
    IoPerson,
    IoPersonOutline
} from "react-icons/io5";
//cont { RoleId,setRoleId} = useContext(RolesContext)

const navigationBarItems = [
    {
        activeIcon: <IoHome/>,
        defaultIcon: <IoHomeOutline/>,
        path: "/home",
        text: "Accueil",
    },
    {
        activeIcon: <IoPeople/>,
        defaultIcon: <IoPeopleOutline/>,
        path: "/eleves",
        text: "Elèves",
    },
    {
        activeIcon: <IoPerson/>,
        defaultIcon: <IoPersonOutline/>,
        path: "/pointage",
        text: "Professeurs ",
    },
    {
        activeIcon: <IoBookmark/>,
        defaultIcon: <IoBookmarkOutline/>,
        path: "/matieres",
        text: "Matières",
    },
    {
        activeIcon: <IoCalendar/>,
        defaultIcon: <IoCalendarOutline/>,
        path: "/emploi-du-temps",
        text: "Emploi du temps",
    },
    {
        activeIcon: <IoAlarm/>,
        defaultIcon: <IoAlarmOutline/>,
        path: "/absences",
        text: "Absences et retards",
    },
    {
        activeIcon: <IoDocumentText/>,
        defaultIcon: <IoDocumentTextOutline/>,
        path: "/notes",
        text: "Notes et bulletins",
    },
    {
        activeIcon: <IoBagRemove/>,
        defaultIcon: <IoBagRemoveOutline/>,
        path: "/paiements",
        text: "Paiements",
    },
    // {
    //   activeIcon: <IoAnalytics />,
    //   defaultIcon: <IoAnalyticsOutline />,
    //   path: "/compta",
    //   text: "Comptabilité",
    // },
]

const Navbar = () => {
    const {RoleId, setRoleId} = useContext(RolesContext)
    const navList = navebareFonction(RoleId)
    const {toggleMenu, isMenuOpen} = useContext(ClassContext);
    const navigate = useNavigate();

    const handleNavigation = (e, path) => {
        e.preventDefault();
        toggleMenu();
        navigate(path);
    };

    return (
        <nav className={isMenuOpen ? "nav active" : "nav"}>
            <div className="top">
                <NavLink to="/home" className="logo">
                    C
                </NavLink>
                <div className="close hidden" onClick={toggleMenu}>
                    +
                </div>
            </div>

            <div className="links">
                {navigationBarItems.map((nav, index) => (
                    <NavLink
                        to={nav.path}
                        key={index}
                        onClick={(e) => handleNavigation(e, nav.path)}
                    >
                        <div className="icon default">{nav.defaultIcon}</div>
                        <div className="icon active">{nav.activeIcon}</div>

                        <span className="full-text text">{nav.text}</span>
                        <span className="one-word text">{nav.text.split(" ")[0]}</span>
                    </NavLink>
                ))}
            </div>
        </nav>
    );
};

export default Navbar;
