import React from 'react'
import { IoArrowForward, IoCalendarOutline } from 'react-icons/io5'

const ComptaBox = () => {
  return (
    <div className="compta__box">
      <div className="icon"><IoArrowForward /></div>
      
      <div className="price">150 000 FCFA</div>
      
      <div className="lib">Achat d'un PC laptop</div>

      <div className="date">
        <span>Aujourd'hui, 12:45</span>
        <span className="date__icon">
            <IoCalendarOutline />
        </span>
      </div>
    </div>
  )
}

export default ComptaBox