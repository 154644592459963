import React, { useContext } from "react";
import UserWithActionBtn from "../../components/user/UserWithActionBtn";
import { ClassContext } from "../../contexts/ClassContext";
import { GetPointages } from "../../API/react_query/react_query";
import {
  getDteforAPI,
  groupPointagetageByTime,
  sortByStartTime,
  timeForPointage,
} from "../../utils/fonctions";
import { PointerUnProf } from "../../API/fonctions/PointerUnProf";
import { accountService } from "../../API/service/account.service";
import Loading from "../../layouts/Loading";

const Presences = () => {
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);
  const {
    isError: isErrorProf,
    isLoading: isLoadingProf,
    error: errorProf,
    refetch: refetchEProf,
    isFetching: isFetchingProf,
    data: dataProf,
  } = GetPointages(getDteforAPI(), anneeScol);

  const pointer = (e, id_cours, id_prof, heur_debut, heur_fin) => {
    e.preventDefault();
    const id_ecole = accountService.getEcole();
    const body = {
      cours_id: id_cours,
      professeur_id: id_prof,
      date: getDteforAPI(),
      ecole_id: id_ecole,
      annee_id: anneeScol,
      check_in_time: getDteforAPI() + " " + timeForPointage(heur_debut),
      check_out_time: getDteforAPI() + " " + heur_fin,
    };

    //// console.log(body)
    const repons = PointerUnProf(body, refetchEProf);
    // console.log("la vie est belle et monotone :", id_cours, body);
  };

  return (
    <div className="main-content presences grid-2">
      {dataProf && dataProf.cours.length > 0 ? (
        groupPointagetageByTime(sortByStartTime(dataProf.cours)).map(
          (index) => (
            <>
              <h3>{index.timeKey}</h3>

              {index.cours?.map(
                (cour, index) =>
                  cour.professeur && (
                    <UserWithActionBtn
                      key={index}
                      user={{ nom_complet: cour.professeur?.nom_complet }}
                      subtitle={
                        cour.heure_debut.slice(0, 5) +
                        " - " +
                        cour.heure_fin.slice(0, 5) +
                        ", " +
                        cour.matiere?.libelle
                      }
                      btnText="Marquer présent"
                      btnFn={(e) => {
                        pointer(
                          e,
                          cour.id,
                          cour.professeur.id,
                          cour.heure_debut,
                          cour.heure_fin
                        );
                      }}
                    />
                  )
              )}
            </>
          )
        )
      ) : (
        <div className="empty medium">Pas de professeur à pointer</div>
      )}

      {isLoadingProf || (isFetchingProf && <Loading size="medium" />)}
    </div>
  );
};

export default Presences;
