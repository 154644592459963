import React from "react";
import { IoWarningOutline } from "react-icons/io5";
import Button from "../button/Button";
import "../../../assets/css/boxwithbutton.css";

const BoxWithButton = ({ icon, contentText, buttonText, onButtonClick }) => {
  return (
    <div className="box-with-button">
      <div className="text">
        <div className="icon">{icon}</div>
        <div className="ctn">{contentText}</div>
      </div>

      <Button text={buttonText} onClick={onButtonClick} />
    </div>
  );
};

export default BoxWithButton;
