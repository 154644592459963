import React, { Children, useState } from 'react'
import { IoCheckmarkSharp } from 'react-icons/io5'
import '../../assets/css/toggleselectbox.css'

const ToggleSelectBox =  ({ id, toggleFn, children }) => {
    const [ checked, setChecked ] = useState(false);

    const toggleChecked = () => {
        setChecked(prev => !prev);
        toggleFn(id, checked)
    }

  return (
    <div
        className={checked ? 'toggle__select__box checked' : 'toggle__select__box'}
        onClick={toggleChecked}
    >
        {children}
        
        {checked && <div className="checker">
            <IoCheckmarkSharp />
        </div>}
    </div>
  )
}

export default ToggleSelectBox