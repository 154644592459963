import React, { useContext, useEffect, useRef, useState } from "react";
import SubSidebar from "../../layouts/SubSidebar";
import "../../assets/css/notes.css";
import Navbar from "../../layouts/Navbar";
import Header from "../../layouts/Header";
import ClassesList from "../../layouts/ClassesList";
import { results } from "../../data/List";
import NotesList from "./NotesList";
import { ClassContext } from "../../contexts/ClassContext";
import NotesStats from "./NotesStats";
import DropdownFilter from "../../components/ui/DropdownFilter";
import {
  GetAllBultins,
  GetEvalMatierQuery,
  GetMatClasseQuery,
  GetNotesQuery,
} from "../../API/react_query/react_query";
import isEqual from "lodash/isEqual";
import BulletinViewer from "./bulletins/BulletinViewer";
import Loading from "../../layouts/Loading";
import { getPageNumber, minMaxAvg } from "../../utils/fonctions";
import { IoArrowForward } from "react-icons/io5";


const Notes = () => {
  const [switched, setSwitched] = useState(false);
  const [showBulletin, setShowBulletin] = useState(false);
  const [bulletins, setBulletins] = useState(false);
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);
  const [currentMatiere, setCurrentMatiere] = useState(0);
  const [currentEval, setCurrentval] = useState(0);
  const [test, setTest] = useState("devoir1");
  const [subject, setSubject] = useState("Mathématiques");
  const [allBultins, setAllBultins] = useState([]);
  const [donneDiag, setDonneDiagram] = useState([]);
  const [pageBultin, setPageBultin] = useState(1);
  const [lastPage, setLastPage] = useState(200);
  const [chargement, setChargement] = useState(true);


  const afficherBulletin = () => {
    setShowBulletin(true);
  };

  const fermerBulletin = () => {
    setShowBulletin(false);
  };

  const {
    isError: isErrorBultin,
    isLoading: isLoadingBultin,
    error: errorBultin,
    isFetching: isFetchingBultin,
    data: dataBultin,
  } = GetAllBultins(currentClass, 3, anneeScol, pageBultin);

  const {
    isError: isErrorMat,
    isLoading: isLoadingMat,
    error: errorMat,
    isFetching: isFetchingMat,
    data: dataMat,
  } = GetMatClasseQuery(currentClass, anneeScol);

  // Add error handling and logging
  useEffect(() => {
    if (isErrorMat) {
      console.error("Error fetching matières:", errorMat);
    }
  }, [isErrorMat, errorMat]);

  const {
    isError: isErrorEval,
    isLoading: isLoadingEval,
    error: errorEval,
    refetch: refetchEval,
    isFetching: isFetchingEval,
    data: dataEval,
  } = GetEvalMatierQuery(currentMatiere, anneeScol);
  const {
    isError: isErrorNote,
    isLoading: isLoadingNote,
    error: errorNote,
    refetch: refetchNote,
    isFetching: isFetchingNote,
    data: dataNote,
  } = GetNotesQuery(currentEval);

  const prevListRef = useRef();

  useEffect(() => {
    if (!isEqual(prevListRef.current, dataMat)) {
      // Code à exécuter si la valeur de la liste a changé
      if (dataMat) {
        if (dataMat.length > 0) {
          setCurrentMatiere(dataMat[0]?.id);
        }

        //refetchEval();
      }
    }
    // Mettre à jour la référence de la liste précédente
    prevListRef.current = dataMat;
  }, [dataMat]);

  useEffect(() => {
    if (!isEqual(prevListRef.current, dataBultin)) {
      // Code à exécuter si la valeur de la liste a changé
      let page = pageBultin + 1;
      if (dataBultin) {
        setAllBultins((prevBultins) => [...prevBultins, ...dataBultin.data]);
        if (pageBultin != dataBultin.last_page) {
          //
          //   setPageBultin(getPageNumber(dataBultin.next_page_url));
          setPageBultin(page);
        } else {
          setChargement(false);
        }

        //refetchEval();
        if (lastPage) {
          setLastPage(dataBultin.last_page);
        }
      }
    }
    // Mettre à jour la référence de la liste précédente
    prevListRef.current = dataBultin;
  }, [dataBultin]);
  // useEffect(() => {
  //   let page = pageBultin + 1;
  //   if (dataBultin?.data?.length > 0){
  //     console.log("dataBultin", dataBultin);
  //     console.log('page bulletin: ', page);
  //     setLastPage(dataBultin.last_page)
  //     setAllBultins((prevBulletins) => [...prevBulletins, ...dataBultin.data]);
  //     if (page <= dataBultin.last_page){
  //       setPageBultin(page)
  //     }
  //     else {
  //       setChargement(false)
  //     }
  //   }
  // }, [dataBultin]);

  useEffect(() => {
    if (!isEqual(prevListRef.current, dataEval)) {
      // Code à exécuter si la valeur de la liste a changé
      if (dataEval?.data.length > 0) {
        // // console.log("les evaluations", dataEval);
        setCurrentval(dataEval.data[0].id);
      } else {
        setCurrentval(null);
      }
    }
    // Mettre à jour la référence de la liste précédente
    prevListRef.current = dataEval;
  }, [dataEval]);

  const createChartData = (list) => {
    var min = 0;
    var mean = 0;
    var max = 0;

    list.forEach((item) => {
      // // console.log(item);
      if (item < 8) {
        min++;
      } else if (item >= 8 && item <= 14) {
        mean++;
      } else {
        max++;
      }
    });
    return [min, mean, max];
  };

  // LISTE EN FONCTION DE LA MATIERE
  var newList = results[test].filter((item) => item.subject == subject && item);

  const updateList = (id) => {
    // fetch
  };

  const switchPages = (page) => {
    page == "Notes" ? setBulletins(false) : setBulletins(true);
    page == "Tous les bultins" ? setShowBulletin(true) : setShowBulletin(false);
  };

  useEffect(() => {
    setDonneDiagram(dataNote ? dataNote.data.map((item) => item.valeur) : []);
  }, [dataNote]);

  const switchClass = () => {
    if (switched) return "main-content with-cols notes switcher switched";
    else return "main-content with-cols notes switcher";
  };

  return (
    <>
      <Navbar />
      <Header title={"Notes de la " + currentClassName} />
      <ClassesList refetchFn={updateList} />

      <main>
        <SubSidebar
          filterList={["Notes", "Bulletins"]}
          dropdown={false}
          filterFn={switchPages}
        />

        <div className={switchClass()}>
          {!currentClass && (
            <div className="vide">
              Aucune classe en ce moment pour afficher des notes
            </div>
          )}

          {/*{bulletins ? (*/}
          {/*  <div className="vide medium">*/}
          {/*    Toutes les notes ne sont pas disponibles pour pouvoir générer des*/}
          {/*    bulletins*/}
          {/*  </div>*/}
          {/*) : (*/}
          <div className="list first-page">
            <div className="filter__section">
              {/*<DropdownFilter*/}
              {/*list={*/}
              {/*  [*/}
              {/*    {*/}
              {/*      id: 1,*/}
              {/*      titre : "semeestre 1"*/}

              {/*    },*/}
              {/*    {*/}
              {/*      id: 2,*/}
              {/*      titre : "semestre 2"*/}

              {/*    }*/}
              {/*  ]*/}
              {/*}*/}
              {/*/>*/}
              <div className="slider">
                {isLoadingMat ||
                !dataMat ||
                JSON.stringify(dataMat) === "[]" ? (
                  <div>- - - </div>
                ) : isErrorMat ? (
                  <div>
                    Erreur lors du chargement des matières. Veuillez réessayer.
                  </div>
                ) : (
                  !bulletins && (
                    <DropdownFilter
                      list={[
                        ...new Set(
                          dataMat?.map((item) => ({
                            id: item?.id,
                            titre: item?.libelle,
                          }))
                        ),
                      ]}
                      setCurrentMatiere={setCurrentMatiere}
                    />
                  )
                )}

                {!dataEval ||
                dataEval?.length === 0 ||
                JSON.stringify(dataMat) === "[]" ? (
                  <div>- - -</div>
                ) : (
                  <>
                    {dataEval && !bulletins && (
                      <DropdownFilter
                        list={[
                          ...new Set(
                            dataEval.data.map((item) => ({
                              id: item.id,
                              titre: item.nom,
                            }))
                          ),
                        ]}
                        setCurrentMatiere={setCurrentval}
                      />
                    )}
                  </>
                )}

                <div className="switch-btn" onClick={() => setSwitched(true)}>
                  <span className="text">Statistiques</span>
                  <span className="icon">
                    <IoArrowForward />
                  </span>
                </div>
              </div>
            </div>

            {dataEval == undefined ||
            dataNote == undefined ||
            !dataNote ||
            !dataNote?.data.length ||
            JSON.stringify(dataNote?.data) === "[]" ||
            dataMat === undefined ||
            JSON.stringify(dataMat) === "[]" ? (
              <div className="vide small">
                Les évaluations ne sont pas encore disponibles
              </div>
            ) : (
              <NotesList
                results={[
                  ...new Set(
                    dataNote?.data?.map((item) => ({
                      id_eleve: item.eleve.eleve.id,
                      matricule: item.eleve.eleve.matricule,
                      note: item?.valeur,
                      nom_complet: item.eleve.eleve.nom_complet,
                      id_evaluation: item.evaluation.id,
                      id_note: item.id,
                      id_inscription: item.inscription_id,
                    }))
                  ),
                ]}
                test={test}
                bulletins={bulletins}
                refetcFn={refetchNote}
              />
            )}
            {isLoadingEval ||
              isLoadingNote ||
              (isLoadingMat && <Loading size="medium" />)}
          </div>
          {/*)}*/}

          {/* ===================== STATISTIQUES ===================== */}

          {donneDiag?.length > 0 ? (
            <NotesStats
              chartData={createChartData(donneDiag)}
              moyenneNote={minMaxAvg(donneDiag)}
              bulletins={bulletins}
              afficherBulletin={afficherBulletin}
              setSwitched={setSwitched}
            />
          ) : (
            <div></div>
          )}
        </div>
      </main>

      {showBulletin & chargement && (
        <Loading
          size="fullscreen"
          text={
            "Génération des bulletins en cours, cette opération peut prendre quelques minutes..."
          }
          progress={((pageBultin / lastPage) * 100).toFixed(0)}
        />
      )}

      {showBulletin & !chargement && (
        <BulletinViewer bulletins={allBultins} onClose={fermerBulletin} />
      )}
    </>
  );
};

export default Notes;
