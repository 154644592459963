import React, {useEffect, useState} from 'react';
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import QRCode from "qrcode";

// Composant Footer du bulletin
const BulletinFooter = ({
                            qrCodeBulletinData,
                            dateEdition
                        }) => {

    return(
        <View style={styles.footerContainer}>
            <View style={styles.qrCodeContainer}>
                <Image src={`data:image/png;base64,${qrCodeBulletinData}`} style={styles.qrCode} />
            </View>

            {qrCodeBulletinData &&
                <Text style={styles.warningText}>
                    Scannez le QR code pour comparer avec la version numérique et vérifier l'authenticité.
                </Text>
            }

            <View style={styles.dateBar}>
                <Text>Ce bulletin a été généré automatiquement par Caly le {dateEdition}</Text>
            </View>
        </View>
    )
};

// Définition des styles pour le footer
const styles = StyleSheet.create({
    footerContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
    },
    qrCodeContainer: {
        marginBottom: 10,
        marginHorizontal: 10
    },
    qrCodeSection: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    qrCodeSectionRight: {
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    qrCodeLabel: {
        fontSize: 9,
        marginBottom: 5,
    },
    qrCode: {
        width: 80,
        // height: 80,
    },
    warningText: {
        fontSize: 9,
        marginBottom: 5,
        marginHorizontal: 10
    },
    dateBar: {
        backgroundColor: '#c0c0c0',
        padding: '5 10',
        textAlign: 'right',
        fontSize: 9,
    }
});

export default BulletinFooter;
