import React, { useState, useContext, useEffect } from "react";
import DropdownFilter from "../../components/ui/DropdownFilter";
import DayPlanBox from "./DayPlanBox";
import AddButton from "../../components/ui/button/AddButton";
import EvaluationAddForm from "./EvaluationAddForm";
import { GetEvalClasse } from "../../API/react_query/react_query";
import { ClassContext } from "../../contexts/ClassContext";
import { groupByDate } from "../../utils/fonctions";
import Loading from "../../layouts/Loading";
import { formatDate } from "../../utils/dateConversion";
import ConfimDelletEvaluation from "./ConfimDelletEvaluation";

const Evaluations = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [evalutionDelet, setEvalutionDelet] = useState({});
  const [matiereDelet, setMatiereDelet] = useState({});
  const [evaluations, setEvaluations] = useState([]);
  const { currentClassName, currentClass, anneeScol } =
    useContext(ClassContext);

  const {
    isError: isErrorEval,
    isLoading: isLoadingEval,
    error: errorEval,
    refetch: refetchEval,
    isFetching: isFetchingEval,
    data: dataEval,
  } = GetEvalClasse(currentClass, anneeScol);

  useEffect(() => {
    refetchEval();
  }, [isOpen, isDeleteOpen]);

  useEffect(() => {
    if (dataEval?.data) {
      setEvaluations(groupByDate(dataEval.data));
    }
  }, [dataEval]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const deletEval = (evalutionDelet1, matier) => {
    console.log("la vie est belle :", evalutionDelet1);
    setEvalutionDelet(evalutionDelet1);
    setMatiereDelet(matier);
    setIsDeleteOpen(true);
  };

  return (
    <div className="evaluations">
      <h3>Liste des évaluations </h3>
      {evaluations && evaluations.length > 0 ? (
        evaluations.map((evaluationsParDate) => (
          <div key={evaluationsParDate.date}>
            <h3>{formatDate(evaluationsParDate.date)}</h3>
            {evaluationsParDate.items.map((evaluation) => (
              <DayPlanBox
                key={evaluation.id} // Assurez-vous d'utiliser une clé unique pour chaque DayPlanBox
                heur_debut={evaluation.heure_debut.slice(0, -3)}
                heur_fin={evaluation.heure_fin.slice(0, -3)}
                // matier={evaluation?.matiere?.libelle}
                matier={
                  evaluation?.matiere?.libelle === "Conduite"
                    ? ""
                    : evaluation?.matiere?.libelle
                }
                evaluation={evaluation}
                commands={true}
                onStop={deletEval}
                // Passez d'autres props nécessaires à DayPlanBox
              />
            ))}
          </div>
        ))
      ) : (
        <div className="empty medium">Aucune évaluation à venir !</div>
      )}

      <AddButton text="Nouvelle évaluation" onOpen={setIsOpen} />

      {isLoadingEval && <Loading size="medium" />}
      {isOpen && <EvaluationAddForm onClose={closeModal} />}

      {isDeleteOpen && (
        <ConfimDelletEvaluation
          onClose={() => setIsDeleteOpen(false)}
          elementId={evalutionDelet.id}
          text={`Êtes-vous sûr de vouloir supprimer ${evalutionDelet?.nom}  ${matiereDelet} ? Cette action est irréversible.`}
          title={`Supprimer ${evalutionDelet?.nom}  ${matiereDelet}`}
        />
      )}
    </div>
  );
};

export default Evaluations;
