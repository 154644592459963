import React from "react";

const LoginImageSection = () => {
  return (
    <div className="image__section">
      {/* <p>Pour changer le monde, il suffit<br />de faire les bon choix.</p> */}
      <p></p>
    </div>
  );
};

export default LoginImageSection;
