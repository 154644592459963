import React from 'react';
import {StyleSheet, View} from "@react-pdf/renderer";
import InlineLabelAndValue from "./InlineLabelAndValue";
import {formatDate} from "../../../../utils/dateConversion";

const BulletinInfos = ({studentInfos, classInfos}) => {
    const studentInfosFields = [
        {label: 'Identifiant', value: studentInfos.calyId},
        {label: 'Nom complet', value: studentInfos.fullname},
        {label: 'Ne(e) le', value: `${formatDate(studentInfos.birthDate, true, false)} à ${studentInfos.birthPlace}`},
        // {label: 'Sexe', value: studentInfos.sex},
        ...(studentInfos.sex ? [{label: 'Sexe', value: studentInfos.sex}] : []),
        // {label: 'Classe', value: studentInfos.className},
        {label: 'Classe doublée', value: studentInfos.doubledClass},
        // {label: 'Eps', value: studentInfos.eps},
    ];
    const classInfosFields = [
        // {label: 'Téléphone', value: classInfos.phone},
        // {label: 'Email', value: classInfos.email},
        {label: 'Niveau', value: classInfos.level},
        // {label: 'Série', value: classInfos.series},
        ...(classInfos.series ? [{label: 'Série', value: classInfos.series}] : []),
        {label: 'Classe', value: classInfos.className},
        {label: 'Effectif', value: classInfos.classSize + ' élèves'},
        // {label: 'Moyenne classe', value: classInfos.classAverage},
        {label: 'EPS', value: classInfos.eps},
    ];
    return (
        <View style={styles.moreInfos}>
            <View style={styles.infoBox}>
                {studentInfosFields.map((info, index) => (
                    <InlineLabelAndValue key={`${info.value}_${index}`} label={info.label} value={info.value}/>
                ))}
            </View>

            <View style={styles.infoBox}>
                {classInfosFields.map((clasInfo, index) => (
                    <InlineLabelAndValue key={`${clasInfo.value}_${index}`} label={clasInfo.label} value={clasInfo.value}/>
                ))}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    moreInfos: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 10,
        marginBottom: 10,
        border: "1 solid #111",

    },
    infoBox: {
        paddingTop: "5",
        paddingHorizontal: 10,
        width: "50%",
    },
});


export default BulletinInfos;
