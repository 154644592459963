import React, { useState } from "react";
import PopupBg from "../../components/ui/modal/PopupBg";
import { IoBarChartOutline } from "react-icons/io5";
import { modifNote } from "../../API/fonctions/modifierNote";
import PopupForm from "../../components/ui/modal/PopupForm";

const EditMarkPopup = ({ onClose, student }) => {
  const [note, setNote] = useState(student.note);
  const [messageErreur, setMessageErreur] = useState("");
  const [isErreur, setIsErreur] = useState(false);

  // console.log(student);

  const modifote = (e) => {
    e.preventDefault();

    const body = {
      inscription_id: student.id_inscription,
      evaluation_id: student.id_evaluation,
      notes: note,
    };

    if (note !== "") {
      if (Number(note) > 20 || Number(note) < 0) {
        setIsErreur(true);
        setMessageErreur("La note doit être entre 0 et 20.");
      } else {
        const repons = modifNote(
          body,
          student.id_note,
          onClose,
          setIsErreur,
          setMessageErreur
        );
      }
    } else {
      setIsErreur(true);
      setMessageErreur("saisissez une notes valide.");
    }
  };

  return (
    <PopupForm
      title="Modifier la note"
      onClose={onClose}
      onSubmit={(e) => modifote(e)}
    >
      <div className="fields-container">
        <p className="input__box">
          <div className="icon">
            <IoBarChartOutline />
          </div>
          <input
            type="number"
            step="1"
            className="message"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
            placeholder="Nouvelle note ..."
            pattern="\d*"
          />
        </p>
        {isErreur && (
          <p style={{ color: "red", padding: "10px" }}>{messageErreur}</p>
        )}
      </div>
    </PopupForm>
  );
};

export default EditMarkPopup;
