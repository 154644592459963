import React, { useContext, useEffect, useState } from "react";
import { IoBookmarkOutline, IoCalendarOutline, IoTimerOutline } from "react-icons/io5";
import { ClassContext } from "../../contexts/ClassContext";
import { GetMatClasseQuery } from "../../API/react_query/react_query";
import { addCours } from "../../API/fonctions/addCours";
import PopupForm from "../../components/ui/modal/PopupForm";
import DropdownList from "../../components/form/DropdownList";
import TimeRangeInput from "../../components/form/TimeRangeInput";
import Loading from "../../layouts/Loading";
import ErrorBox from "../../components/ui/ErrorBox";
import { modierCours } from "../../API/fonctions/modierCours";

const EditCoursePopup = ({
  onClose,
  cours,
  matiere,
  jour,
  timeRange,
  existingSubjects,
  refetchEmp,
}) => {
  const [messageErreur, setMessageErreur] = useState("");
  const { currentClass, anneeScol } = useContext(ClassContext);
  const {
    data: subjects,
    isLoading,
    isError,
    error,
  } = GetMatClasseQuery(currentClass, anneeScol);

  const [selectedSubject, setSelectedSubject] = useState(cours.matiere);
  const [selectedDaye, setSelectedDaye] = useState({ id: jour, content: jour });
  const [courseTimeRange, setCourseTimeRange] = useState(timeRange);

  //console.log("les cours :",cours)

  




  useEffect(() => {
    if (subjects && subjects.length > 0) {
      const availableSubjects = subjects.filter(
        (subject) => !existingSubjects.includes(subject.id)
      );
      if (availableSubjects.length > 0) {
        //setSelectedSubject(availableSubjects[0]);
      }
    }
  }, [subjects, existingSubjects]);

  const handleSubmit = () => {
    if (!selectedSubject) return;

    const courseData = {
      jour:selectedDaye.id,
      heure_debut: courseTimeRange.start,
      heure_fin: courseTimeRange.end,
      id_prof: selectedSubject.professeur?.id || null,
      id_matiere: selectedSubject.id,
      id_class: currentClass,
      id_salle: 1, // TODO: Make this dynamic if needed
      annee_id: anneeScol,
    };
    

    modierCours(courseData,cours.id_cours, onClose, setMessageErreur, refetchEmp);
  };

  const handleSubjectSelect = (subjectId) => {
    const subject = subjects.find((s) => s.id.toString() === subjectId);
    setSelectedSubject(subject);
  };
  const handleSubjectSelectJours = (subjectId) => {
    const subject = joursDeLaSemaine.find((s) => s.content.toString() === subjectId);
    setSelectedDaye(subject);
  };

  if (isLoading) return <Loading size="small" />;
  if (isError) return <div>Error: {error.message}</div>;

  const subjectItems = subjects
    .filter((subject) => !existingSubjects.includes(subject.id))
    .map((subject) => ({
      id: subject.id.toString(),
      content: subject.libelle,
    }));
    const joursDeLaSemaine = [
        { id: "lundi", content: "lundi" },
        { id: "mardi", content: "mardi" },
        { id: "mercredi", content: "mercredi" },
        { id: "jeudi" , content: "jeudi" },
        { id: "vendredi", content: "vendredi" },
        { id: "samedi", content: "samedi" },
      ];

  return (
    <PopupForm
      onClose={onClose}
      title="Modifier le cours"
      onSubmit={handleSubmit}
    >
        {messageErreur && <ErrorBox message={"Le professeur est déjà en cours à cette heure"} />}

      <DropdownList
        icon={<IoCalendarOutline />}
        items={joursDeLaSemaine}
        value={selectedDaye.content}
        setValue={handleSubjectSelectJours}
        placeholder="jour"
      />
            <DropdownList
        icon={<IoBookmarkOutline />}
        items={subjectItems}
        value={selectedSubject?.id.toString()}
        setValue={handleSubjectSelect}
        placeholder="Sélectionnez une matière"
      />
      <TimeRangeInput
        icon={<IoTimerOutline />}
        value={courseTimeRange}
        onChange={setCourseTimeRange}
      />
    </PopupForm>
  );
};

export default EditCoursePopup;
