//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken, uploadTokenPUT } from "./upload";


const token = accountService.getToken();

const headers = { Authorization: `Bearer ${token}` };

async function modierMatie(body, onClose,id_matier) {
    const endpoint = "matieres/" + id_matier;

    const reponse = await uploadTokenPUT(endpoint, body, headers);

    if (reponse.ok == true) {
        onClose()
    }
    else {
        const errorDetails = await reponse.json();
        console.error("Erreur serveur:", reponse.status, errorDetails);

    }


}

export { modierMatie };
