import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const BulletinTitle = ({ title = "BULLETIN DU PREMIER SEMESTRE" }) => (
    <View style={styles.titleContainer}>
        <Text style={styles.titleText}>{title}</Text>
    </View>
);

const styles = StyleSheet.create({
    titleContainer: {
        backgroundColor: '#C0C0C0', // Couleur grise similaire à celle de l'image
        padding: 5,
        marginBottom: 10,
        width: '100%',
        textAlign: 'center',
    },
    titleText: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#000000',
        textTransform: 'uppercase',
    },
});

export default BulletinTitle;
