import React, { useContext, useState } from "react";
import { ClassContext } from "../../contexts/ClassContext";
import InfoBox from "../../components/ui/box/InfoBox";
import ConfirmDeletePopup from "../../components/ui/modal/ConfirmDeletePopup";
import { motion } from "framer-motion";

import {
  IoBookmarkOutline,
  IoBookOutline,
  IoCalendarOutline,
  IoFemaleOutline,
  IoMaleOutline,
  IoPeopleOutline,
  IoPricetagOutline,
  IoWarningOutline,
} from "react-icons/io5";
import BoxWithButton from "../../components/ui/box/BoxWithButton";

const ClassInfos = () => {
  const { currentClass, currentClassName } = useContext(ClassContext);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <motion.div
      className="class-infos"
      initial={{ y: "40%" }}
      animate={{ y: 0 }}
      exit={{ scale: 0.5 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="grid-2">
        <div className="part">
          <h3>Informations de la {currentClassName}</h3>
          <InfoBox
            icon={<IoPeopleOutline />}
            title="Nombre d'élèves"
            value={45 + " élèves"}
            type="contained"
          />
          <InfoBox
            icon={<IoMaleOutline />}
            title="Nombre de garçons"
            value={23 + " garçons"}
            type="contained"
          />
          <InfoBox
            icon={<IoFemaleOutline />}
            title="Nombre de filles"
            value={22 + " filles"}
            type="contained"
          />
          <InfoBox
            icon={<IoBookOutline />}
            type="contained"
            title="Niveau"
            value=" Seconde"
          />
          <InfoBox
            icon={<IoBookmarkOutline />}
            title="Série"
            value=" Série S"
            type="contained"
          />
        </div>

        <div className="part">
          <h3>Frais de scolarité</h3>

          <InfoBox
            icon={<IoPricetagOutline />}
            title="Montant de l'inscrption"
            value={"45 000 Fr CFA"}
            type="contained"
          />

          <InfoBox
            icon={<IoCalendarOutline />}
            title="Montant de la mensualité"
            value={"20 000 Fr CFA"}
            type="contained"
          />
        </div>
      </div>

      <BoxWithButton
        icon={<IoWarningOutline />}
        contentText={"Cette classe n'a aucun élève. Voulez-vous la supprimer ?"}
        buttonText={"Supprimer la classe"}
        onButtonClick={() => setIsDeleteOpen(true)}
      />

      {isDeleteOpen && (
        <ConfirmDeletePopup
          onClose={() => setIsDeleteOpen(false)}
          // elementId={currentSubject.id}
          text={`Êtes-vous sûr de vouloir supprimer la classe "${currentClassName}" ? Cette action est irréversible.`}
          title={"Supprimer la classe " + currentClassName}
        />
      )}
    </motion.div>
  );
};

export default ClassInfos;
