// contexts/RolesContext.js
import React, { createContext, useEffect, useState } from "react";
import { accountService } from "../API/service/account.service";
import { GetUserQuery } from "../API/react_query/react_query";

export const RolesContext = createContext();

const RolesContextPrivider = ({ children }) => {
  const [RoleId, setRoleId] = useState(accountService.getRoleUser());

  const {
    isError: isErrorUser,
    isLoading: isLoadingUser,
    error: errorUser,
    refetch: refetchUser,
    isFetching: isFetchingUser,
    data: dataUser,
  } = GetUserQuery();
  // console.log("l'tulisateur connecter :",dataUser)
  const [token, setToken] = useState(accountService.getToken());
  // console.log("role id", RoleId);

  useEffect(() => {
    if (!isLoadingUser) {
      // // console.log("verification des classes ")
      setRoleId(dataUser.data.role.id);
    }
  }, [dataUser]);

  // Définir les permissions pour chaque rôle
  const rolePermissions = {
    1: [
      "/",
      "/home",
      "/eleves",
      "/emploi-du-temps",
      "/absences",
      "/notes",
      "/paiements",
      "/compta",
      "/pointage",
      "/matieres",
      "/classe",
    ],
    4: [
      "/",
      "/home",
      "/eleves",
      "/emploi-du-temps",
      "/absences",
      "/notes",
      "/paiements",
      "/pointage",
      "/matieres",
      "/classe",
    ],
    6: ["/", "/home", "/paiements", "/compta"],
  };

  // Vérifie si le rôle actuel a accès à un chemin donné
  const hasAccess = (path) => rolePermissions[RoleId]?.includes(path);

  return (
    <RolesContext.Provider value={{ RoleId, setRoleId, hasAccess }}>
      {children}
    </RolesContext.Provider>
  );
};

export default RolesContextPrivider;
