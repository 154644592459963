import React from "react";
import "../../assets/css/userbox.css";
import { IoPerson } from "react-icons/io5";

const UserBox = ({ subtitle, user, onClick, withBg = false }) => {
  if (!user) return;

  return (
    <div className={withBg ? "user bg" : "user"} onClick={() => onClick(user)}>
      <div className="profil">
        <span className="icon">
          <IoPerson />
        </span>
      </div>
      <div className="details">
        <div className="name">
          {user?.nom_complet} {user?.telephone}
        </div>
        <span className="subtitle">{subtitle}</span>
      </div>
    </div>
  );
};

export default UserBox;
