import React from 'react';
import {StyleSheet, Text, View} from "@react-pdf/renderer";

const ClassGrade = ({classGrade}) => {
    return (
        <View style={styles.summaryRow}>
            <View style={[styles.infoCell]}>
                <Text>Moyenne de la classe : <Text style={styles.infoText}>{classGrade.moyenneClasse}</Text> </Text>
            </View>
            <View style={[styles.infoCell]}>
                <Text>Moyenne la plus élevée : <Text style={styles.infoText}>{classGrade.moyennePlusElevee}</Text> </Text>
            </View>
            <View style={[styles.infoCell]}>
                <Text>Moyenne la plus basse : <Text style={styles.infoText}>{classGrade.moyennePlusBasse}</Text></Text>
            </View>
        </View>
    );
};
const styles = StyleSheet.create({
    summaryRow: {
        flexDirection: 'row',
        backgroundColor: '#e4e4e4',
        width: '100%',
        marginTop: 5
    },
    infoCell: {
        padding: 6,
        width: '33%'
    },
    cell: {
        width: '33%',
    },
    infoText: {
        fontWeight: 'semibold'
    }
});

export default ClassGrade;
