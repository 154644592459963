import React, { useState } from "react";
import { Header, Navbar, SubSidebar } from "../../layouts";
import "../../assets/css/compta.css";
import {
  IoArrowBack,
  IoArrowForward,
  IoCalculatorOutline,
} from "react-icons/io5";
import ComptaAddForm from "./ComptaAddForm";
import ComptaBox from "./ComptaBox";
import AddButton from "../../components/ui/button/AddButton";
import EmptyPage from "../../components/ui/EmptyPage";
import waiting from "../../assets/images/waiting.png";

const Accountability = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [available, setAvailable] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Navbar />
      <Header title="Comptabilité" />

      {available ? (
        <div className="main-fullwidth compta main-fullwidth">
          <SubSidebar
            title="Comptabilité"
            filterList={["Tout", "Recettes", "Dépenses", "Avancé"]}
          />

          <div className="main-fullwidth">
            <ComptaBox />
            <ComptaBox />
            <ComptaBox />
            <ComptaBox />
          </div>

          <AddButton text="Nouvelle dépense" onOpen={setIsOpen} />

          {isOpen && <ComptaAddForm closeModal={closeModal} />}
        </div>
      ) : (
        <EmptyPage
          img={waiting}
          title="Fonctionnalité en cours de finalisation"
          description="La comptabilité est entain d'etre revue pour davantage etre simplifiée et sera disponible avant le début des cours"
        />
      )}
    </>
  );
};

export default Accountability;
