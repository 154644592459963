import React, { useContext, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { ClassContext } from "../../contexts/ClassContext";
import ClassInfos from "./ClassInfos";
import EditClassInfos from "./EditClassInfos";

const Classe = () => {
  const { currentClassName } = useContext(ClassContext);
  const [activePage, setActivePage] = useState("Informations");

  const togglePage = (page) => {
    setActivePage(page);
  };

  return (
    <PageLayout
      pageTitle={"Informations de la " + currentClassName}
      pageFilters={["Informations", "Modifications"]}
      pageFilterFn={togglePage}
    >
      {activePage == "Informations" ? <ClassInfos /> : <EditClassInfos />}
    </PageLayout>
  );
};

export default Classe;
