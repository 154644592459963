import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IoArrowDownSharp,
  IoChevronDownOutline,
  IoPencilSharp,
} from "react-icons/io5";
import UserBox from "../../components/user/UserBox";
import DropdownFilter from "../../components/ui/DropdownFilter";
import NoteBox from "./NoteBox";
import EditMarkPopup from "./EditMarkPopup";
import BulletinViewer from "./bulletins/BulletinViewer";
import { ClassContext } from "../../contexts/ClassContext";
import { GetBultin } from "../../API/react_query/react_query";
import { isEqual } from "lodash";
import Loading from "../../layouts/Loading";

const NotesList = ({ openModal, results, bulletins, refetcFn }) => {
  // triage...[[[]]]

  const [isEditing, setIsEditing] = useState(false);
  const [chargement, setChargement] = useState(false);
  const [showBulletin, setShowBulletin] = useState(false);
  const [studentToEdit, setStudentToEdit] = useState();
  const [studentBultin, setStudentBultin] = useState();
  const [bultinEleveData, setBultinEleveData] = useState([]);
  const { anneeScol } = useContext(ClassContext);

  //  pour recuppperer les bultins
  const {
    isError: isErrorBultin,
    isLoading: isLoadingBultin,
    error: errorBultin,
    isFetching: isFetchingBultin,
    data: dataBultin,
  } = GetBultin(studentBultin, 3, anneeScol);
  //  pour recuppperer les bultins
  const prevListRef = useRef();
  useEffect(() => {
    if (!isEqual(prevListRef.current, dataBultin)) {
      // Code à exécuter si la valeur de la liste a changé
      if (dataBultin) {
        setBultinEleveData([dataBultin]);

        //refetchEval();
      }
    }
    // Mettre à jour la référence de la liste précédente
    prevListRef.current = dataBultin;
  }, [dataBultin]);

  useEffect(() => {
    refetcFn();
  }, [isEditing]);

  const openEditionPopup = (std) => {
    setIsEditing(true);
    setStudentToEdit(std);
  };
  const openBultinPopup = (std) => {
    setShowBulletin(true);
    setStudentBultin(std);
    setChargement(true);
  };
  const fermerBulletin = () => {
    setShowBulletin(false);
    setChargement(false);
  };

  const closeEditionPopup = () => {
    setIsEditing(false);
  };

  return (
    <div className="notes">
      {results?.length > 0 ? (
        results.map((student, index) => (
          <NoteBox
            student={student}
            key={index}
            isBulletin={bulletins}
            editFn={openEditionPopup}
            daolnwodFn={openBultinPopup}
          />
        ))
      ) : (
        <div className="vide small">
          Les notes ne sont pas encore disponible
        </div>
      )}

      {isEditing && (
        <EditMarkPopup onClose={closeEditionPopup} student={studentToEdit} />
      )}

      {showBulletin & !isLoadingBultin && (
        <BulletinViewer bulletins={bultinEleveData} onClose={fermerBulletin} />
      )}

      {isLoadingBultin & chargement && (
        <Loading
          size="fullscreen"
          text={"Génération du bulletin en cours ..."}
        />
      )}
    </div>
  );
};

export default NotesList;
