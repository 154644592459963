import React from 'react';
import { View, Text, Image, StyleSheet } from '@react-pdf/renderer';
import logoCaly from "../../../../assets/images/logo_caly_for_light_mode.png";
import logoCPJH from "../../../../assets/images/cjh_logo.png";


const BulletinHeader = ({schoolInfos}) => (
    <View style={styles.header}>
        <View style={styles.leftLogo}>
            <Image src={logoCPJH} />
        </View>

        <View style={styles.infosEcole}>
            <Text style={styles.nomEcole}>{schoolInfos.nom}</Text>
            <Text>{schoolInfos.adresse} | Tel : {schoolInfos.telephone}</Text>
            <Text>Email : {schoolInfos.email}</Text>
            <Text>Année scolaire : {schoolInfos.anneeScolaire}</Text>
        </View>

        <View style={styles.logo}>
            <Image src={logoCaly} />
        </View>
    </View>
);

const styles = StyleSheet.create({
    header: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: 10,
        borderBottom: "5 solid #ff4500",
        marginBottom: 3,

    },
    logo: {
        width: 80,
        height: 'auto',
    },leftLogo: {
        width: 60,
        height: 'auto',
    },
    infosEcole: {
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
    },
    nomEcole: {
        fontWeight: 600,
        fontSize: 12,
    }
});

export default BulletinHeader;
