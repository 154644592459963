import React, { useState } from "react";
import AddButton from "../../components/ui/button/AddButton";
import AddSubjectPopup from "./AddSubjectPopup";
import SubjectsList from "./SubjectsList";
import EditSubjectPopup from "./EditSubjectPopup";
import ConfirmDeletePopup from "../../components/ui/modal/ConfirmDeletePopup";

const AllSubjects = () => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [currentSubject, setCurrentSubject] = useState();

  const toggleEditSubject = (subject) => {
    setIsEditOpen((prev) => !prev);
    setCurrentSubject(subject);
  };

  const toggleDeleteSubject = (sub) => {
    setIsDeleteOpen((prev) => !prev);
    setCurrentSubject(sub);
  };

  const toggleAddPopup = () => {
    setIsAddOpen((prev) => !prev);
  };

  return (
    <>
      <SubjectsList
        btnFn={toggleEditSubject}
        secondBtnFn={toggleDeleteSubject}
        closeFn={isAddOpen}
      />
      <AddButton text="Nouvelle matière" onOpen={toggleAddPopup} />
      {/* {!subjects && (
        <div className="empty">Cette clqsse n'a pas encore de matière</div>
      )} */}
      {isAddOpen && <AddSubjectPopup onClose={toggleAddPopup} />}
      {isEditOpen && (
        <EditSubjectPopup
          onClose={() => setIsEditOpen(false)}
          subject={currentSubject}
        />
      )}

      {isDeleteOpen && (
        <ConfirmDeletePopup
          onClose={() => setIsDeleteOpen(false)}
          elementId={currentSubject.id}
          text={`Êtes-vous sûr de vouloir supprimer la matière "${currentSubject.nom_complet}" ? Cette action est irréversible.`}
          title={"Supprimer "+currentSubject.nom_complet}
        />
      )}
    </>
  );
};

export default AllSubjects;
