import React, { useEffect } from "react";
import PopupBg from "../../components/ui/modal/PopupBg";
import AdminsList from "./AdminsList";
import AddAdmin from "./AddAdmin";
import ShowAdminDetails from "./ShowAdminDetails";
import "../../assets/css/notifications.css";
import { IoPersonAddOutline } from "react-icons/io5";
import { useState } from "react";
import { motion } from "framer-motion";

const Admins = ({ closeModal }) => {
  const [addFormOpened, setAddFormOpened] = useState(false);
  const [adminDetails, setAdminDetails] = useState("");

  const deleteAdminDetails = () => {
    setAdminDetails("");
  };
  // useEffect(()=>{

  // },[addFormOpened])
  const putAdminDetails = () => {
    setAdminDetails("qqch");
  };

  const openAddForm = () => {
    setAddFormOpened(true);
    deleteAdminDetails();
  };

  const closeAddForm = () => {
    setAddFormOpened(false);
  };

  return (
    <PopupBg closeModal={closeModal} opacity="transparent">
      <motion.div
        className="Admins"
        initial={{ translateX: "100%" }}
        animate={{ translateX: 0 }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.3 }}
      >
        {!addFormOpened ? (
          <AdminsList onClose={closeModal} onDetail={putAdminDetails} />
        ) : (
          <AddAdmin onPrev={closeAddForm} />
        )}

        {adminDetails != "" && <ShowAdminDetails />}

        {!addFormOpened && (
          <button className="button__add" onClick={openAddForm}>
            <IoPersonAddOutline />
          </button>
        )}
      </motion.div>
    </PopupBg>
  );
};

export default Admins;
