import React from "react";
import {IoArrowDownSharp, IoDocument, IoDocumentOutline, IoPencilSharp} from "react-icons/io5";
import UserBox from "../../components/user/UserBox";

const NoteBox = ({ student, isBulletin, editFn, daolnwodFn }) => {
  return (
    <div className="note__box">
      <UserBox user={student} subtitle={student.matricule} />

      {!isBulletin ? (
        <button className="options" onClick={() => editFn(student)}>
          <div className="mark">
            <span>{student.note}</span>/20
          </div>

          <div className="icon edit">
            <IoPencilSharp />
          </div>
        </button>
      ) : (
        <button
          className="options"
          onClick={() => daolnwodFn(student.id_inscription)}
          style={{width: "auto", gap: 10}}
        >
          <div className="mark">
            <span>Télécharger</span>
          </div>

          <div className="icon download">
            <IoArrowDownSharp/>
          </div>
        </button>
      )}
    </div>
  );
};

export default NoteBox;
