import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';



// Composant pour l'en-tête du tableau
const GradesTableHeader = () => (
    <View style={[styles.tableRow, styles.tableHeader]}>
        <Text style={styles.disciplineCell}>Disciplines</Text>
        <Text style={styles.standardCell}>Devoirs</Text>
        <Text style={styles.standardCell}>Compos</Text>
        <Text style={styles.standardCell}>Moyenne</Text>
        <Text style={styles.standardCell}>Coef</Text>
        <Text style={styles.standardCell}>Moy×Coef</Text>
        <Text style={styles.standardCell}>Rang</Text>
        <Text style={styles.appreciationCell}>Appréciations</Text>
    </View>
);

// Composant pour une ligne de notes
const GradesTableRow = ({ data, index }) => (
    <View style={[
        styles.tableRow,
        index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd
    ]}>
        <Text style={styles.disciplineCell}>{data.discipline}</Text>
        <Text style={styles.standardCell}>{data.devoirs.toFixed(2)}</Text>
        <Text style={styles.standardCell}>{data.compos.toFixed(2)}</Text>
        <Text style={styles.standardCell}>{data.moy.toFixed(2)}</Text>
        <Text style={styles.standardCell}>{data.coef}</Text>
        <Text style={styles.standardCell}>{data.moyCoef.toFixed(2)}</Text>
        <Text style={styles.standardCell}>{data.rang}</Text>
        <Text style={styles.appreciationCell}>{data.appreciation}</Text>
    </View>
);

// Composant pour le pied du tableau (totaux)
const GradesTableFooter = ({ totals }) => (
    <View style={[styles.tableRow, styles.tableFooter]}>
        <Text style={styles.disciplineCell}>Totaux</Text>
        <Text style={styles.standardCell}></Text>
        <Text style={styles.standardCell}></Text>
        <Text style={styles.standardCell}></Text>
        <Text style={styles.standardCell}>{totals.coef}</Text>
        <Text style={styles.standardCell}>{totals.moyCoef.toFixed(2)}</Text>
        <Text style={styles.standardCell}></Text>
        <Text style={styles.appreciationCell}></Text>
    </View>
);

// Composant principal du tableau de notes
const GradesTable = ({ grades, totals }) => (
    <View style={styles.table}>
        <GradesTableHeader />
        {grades.map((grade, index) => (
            <GradesTableRow key={index} data={grade} index={index} />
        ))}
        <GradesTableFooter totals={totals} />
    </View>
);

const styles = StyleSheet.create({
    table: {
        display: 'flex',
        width: '100%',
        // borderStyle: 'solid',
        // borderWidth: 1,
        // borderColor: '#000',
        marginBottom: 10,
    },
    tableRow: {
        flexDirection: 'row',
        // borderBottomWidth: 1,
        // borderBottomColor: '#000',
        minHeight: 18,
        alignItems: 'center',
    },
    tableRowEven: {
        backgroundColor: '#ffffff',
    },
    tableRowOdd: {
        backgroundColor: '#e4e4e4',
    },
    tableHeader: {
        backgroundColor: '#bdbdbd',
        fontWeight: 'bold',
    },
    tableFooter: {
        backgroundColor: '#bdbdbd',
        fontWeight: 'bold',
    },
    disciplineCell: {
        width: '35%',
        paddingHorizontal: 4,
        borderRightWidth: 1,
        borderRightColor: '#fff',
    },
    standardCell: {
        width: '10%',
        textAlign: 'center',
        borderRightWidth: 1,
        borderRightColor: '#fff',
    },
    appreciationCell: {
        width: '15%',
        padding: 4,
    },
});


export default GradesTable;
