import React from "react";

const InputField = ({
  icon,
  type = "text",
  placeholder,
  value,
  setValue,
  name,
}) => {
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="input__box">
      <div className="icon">{icon}</div>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        name={name}
      />
    </div>
  );
};

export default InputField;
