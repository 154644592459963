import React from "react";
import Navbar from "./Navbar";
import Header from "./Header";
import ClassesList from "./ClassesList";
import SubSidebar from "./SubSidebar";

const PageLayout = ({ pageTitle, pageFilters, pageFilterFn, children }) => {
  return (
    <>
      <Navbar />
      <Header title={pageTitle} />
      <ClassesList />

      <main>
        <SubSidebar filterList={pageFilters} filterFn={pageFilterFn} />

        <div className="main-content">{children}</div>
      </main>
    </>
  );
};

export default PageLayout;
