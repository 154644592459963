import {StyleSheet, Text, View} from "@react-pdf/renderer";

const InlineLabelAndValue = ({label, value}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.label} >{label} :</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    lineHeight: 1
  },
  label: {
    opacity: 0.7,
    textTransform: 'capitalize',

  },
  value: {
    textTransform: 'uppercase',
    fontWeight: 'semibold',
    marginLeft: 5
  }
})

export default InlineLabelAndValue;
