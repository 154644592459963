import React from "react";
import { useState } from "react";
import {
  IoSunny,
  IoGridOutline,
  IoMenuOutline,
  IoNotificationsOutline,
  IoPersonCircleOutline,
  IoSearchOutline,
  IoSettingsOutli,
  IoSunnyne,
  IoMoon,
} from "react-icons/io5";
import { NavLink } from "react-router-dom";
import Notifications from "./Notifications/Notifications";
import Admins from "./Admins/Admins";
import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";
import { ClassContext } from "../contexts/ClassContext";

const Header = ({ title }) => {
  const [isNotifOpened, setIsNotifOpened] = useState(false);
  const [isAdminOpened, setIsAdminOpened] = useState(false);

  const { theme, updateTheme } = useContext(ThemeContext);
  const { toggleMenu } = useContext(ClassContext);

  const closeNotif = () => {
    setIsNotifOpened(false);
  };

  const openNotif = () => {
    setIsNotifOpened(true);
  };

  const closeAdmin = () => {
    setIsAdminOpened(false);
  };

  const openAdmin = () => {
    setIsAdminOpened(true);
  };

  return (
    <article className="sidebar">
      <div className="left-side">
        <div className="burger-menu" onClick={toggleMenu}>
          <IoMenuOutline />
        </div>
        <h2>{title}</h2>
      </div>

      <div className="annexe">
        <div className="icon settings" onClick={() => updateTheme()}>
          {theme == "dark" ? <IoSunny /> : <IoMoon />}
        </div>

        {/* <div className="icon notif" onClick={openNotif}>
                    <IoNotificationsOutline />
                </div> */}

        <div className="icon account" onClick={openAdmin}>
          <IoPersonCircleOutline />
        </div>

        {/* <div className="icon options">
                    <IoMenuOutline />
                </div> */}
      </div>

      {isNotifOpened && <Notifications closeModal={closeNotif} />}
      {isAdminOpened && <Admins closeModal={closeAdmin} />}
    </article>
  );
};

export default Header;
