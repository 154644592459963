import React from "react";
import Bulletin from "./Bulletin";
import {Document, PDFViewer} from "@react-pdf/renderer";

const BulletinViewer = ({ bulletins, onClose, semestre='1er Semestre', eleve='Abdoulaye Sarr' }) => {
  return (
    <div className="bulletin__viewer">
      <div className="header">
        <div className="close" onClick={onClose}>
          +
        </div>
      </div>

      <div className="bulletin">
        <PDFViewer width="100%" height={1024}>
            <Document>
                {bulletins.map((bulletin, index) => <Bulletin key={index} bulletin={bulletin} page={index + 1}/>) }
            </Document>
        </PDFViewer>
      </div>
    </div>
  );
};

export default BulletinViewer;
