import React from 'react'
import UserBox from './UserBox'
import SmallActionButton from '../ui/SmallActionButton'
import '../../assets/css/userwithactionbtn.css'

const UserWithActionBtn = ({ user, subtitle, btnText, btnFn }) => {



  return (
    <div className='user__with__action__btn'>
        <UserBox user={user} subtitle={subtitle}/>
        <SmallActionButton text={btnText} actionFn={ ()=>{btnFn(user)}} />
    </div>
  )
}

export default UserWithActionBtn