import React, { useState } from "react";
import { motion } from "framer-motion";

import {
  IoCalendar,
  IoCalendarOutline,
  IoCall,
  IoDownload,
  IoLocation,
  IoPeople,
  IoPerson,
} from "react-icons/io5";
import PopupBg from "../../components/ui/modal/PopupBg";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { formatPhoneNumber } from "../../utils/fonctions";
import { formatDate } from "../../utils/dateConversion";
import InfoBox from "../../components/ui/box/InfoBox";
import InfosContainer from "./InfosContainer";
import EditableInfosContainer from "./EditableInfosContainer";

const StudentInfos = ({ studentClicked, closeModal, closeModalRefreche }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const toggleEditableInfos = () => {
    setIsEditOpen((prev) => !prev);
  };

  const handleClose = () => {
    closeModal();
  };

  const handleCloseRefrech = () => {
    closeModalRefreche();
  };

  return (
    <PopupBg closeModal={closeModal} opacity="transparent">
      {/* <animated.div style={animated}> */}
      <motion.div
        className="modal"
        initial={{ y: "40%" }}
        animate={{ y: 0 }}
        exit={{ scale: 0.5 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="close" onClick={closeModal}>
          +
        </div>
        <div className="profile__box">
          <div className="icon">
            <IoPerson />
          </div>

          <div className="name__box">
            <div className="name">{studentClicked.nom_complet}</div>
            <div className="matricule">{studentClicked.matricule}</div>
          </div>
        </div>

        {!isEditOpen ? (
          <InfosContainer
            studentClicked={studentClicked}
            onEdit={toggleEditableInfos}
          />
        ) : (
          <EditableInfosContainer
            studentClicked={studentClicked}
            onClose={toggleEditableInfos}
          />
        )}
      </motion.div>
    </PopupBg>
  );
};

export default StudentInfos;
