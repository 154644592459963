import React, { useRef, useState, useEffect } from 'react'
import PopupBg from './modal/PopupBg'
import { IoCheckmark, IoChevronDown } from 'react-icons/io5';
import '../../assets/css/dropdownfilter.css'
import isEqual from 'lodash/isEqual';

const DropdownFilter = ({ list, filterFn, setCurrentMatiere }) => {
    const [isActive, setIsActive] = useState(false);
    const [currentItem, setCurrentItem] = useState(list[0] || {id :1, tire:"titre"});
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

    const prevListRef = useRef();

    useEffect(() => {
        if (!isEqual(prevListRef.current, list)) {
            // Code à exécuter si la valeur de la liste a changé
            if (list.length > 0) {
                setCurrentItem(list[0]);
            }
        }
        // Mettre à jour la référence de la liste précédente
        prevListRef.current = list;
    }, [list]);


    const buttonRef = useRef();

    const closeDropdown = () => {
        setIsActive(false);
    }

    const openDropdown = () => {
        if (buttonRef.current) {
            const buttonCoords = buttonRef.current.getBoundingClientRect();

            setPopupPosition({
                top: buttonCoords.bottom,
                left: buttonCoords.left
            })
        }
        setIsActive(true);
    }

    const handleClick = item => {
        setCurrentItem(item);
        setCurrentMatiere(item.id);
        closeDropdown();
        filterFn(item);
    }

    return (<>
        <div
            ref={buttonRef}
            className='dropdown__filter__box'
            onClick={openDropdown}
        >
            {currentItem?.titre}
            <span className="icon"><IoChevronDown /></span>
        </div>

        {
            isActive &&
            <PopupBg closeModal={closeDropdown} opacity='zero'>
                <div
                    className='dropdown__filter__list'
                    style={{
                        position: 'absolute',
                        top: popupPosition.top,
                        left: popupPosition.left
                    }}
                >
                    {
                        list.map(item =>
                            <div
                                key={item.id}
                                className="dropdown__item"
                                onClick={() => handleClick(item)}
                            >
                                {item.titre}
                                {
                                    item.id == currentItem.id &&
                                    <div className="icon"><IoCheckmark /></div>
                                }
                            </div>
                        )
                    }
                </div>
            </PopupBg>
        }
    </>)
}

export default DropdownFilter
