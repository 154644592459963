import React, { useState } from 'react'
import { IoChevronDownOutline, IoSearchOutline } from 'react-icons/io5'
import SearchBar from './SearchBar'
import '../assets/css/subsidebar.css'

const SubSidebar = ({ filterList, dropdown = false, filterFn, searchFn, withSearchBar = false }) => {
  const [filterState, setFlterState] = useState({
    activeFilter: filterList[0],
    items: filterList
  })

  const setActive = (index, category) => {
    setFlterState({ ...filterState, activeFilter: filterState.items[index] });
    filterFn(category);
  }

  const addActiveClass = index => {
    if (filterState.items[index] === filterState.activeFilter) {
      return 'item active';
    } else {
      return 'item';
    }
  }

  return (
    <article className="sub-sidebar">
      {withSearchBar ? <SearchBar searchFn={searchFn} /> : <div></div>}

      <div className="filter">
        {
          filterState.items.map((item, index) =>
            <div
              key={index}
              className={addActiveClass(index)}
              onClick={() => setActive(index, item)}
            >
              {item}
            </div>
          )
        }
      </div>

      {dropdown ? <div className="dropdown"><IoChevronDownOutline /></div> : ""}
    </article>
  )
}

export default SubSidebar