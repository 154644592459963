import React from "react";
import { IoTimeOutline } from "react-icons/io5";

const CourseBox = ({cour, heureDebut, heureFin, matiere,daye,onClick }) => {
  return (
    <div
    className="course__box"
    onClick={(e) => onClick(cour,heureDebut, heureFin,daye,{id :cour?.matiere.id, content: cour?.matiere?.libelle})}
  >
      <div className="duration">
        <span className="icon">
          <IoTimeOutline />
        </span>
        {heureDebut} - {heureFin}
      </div>
      <div className="subject">{matiere}</div>
    </div>
  );
};

export default CourseBox;
