import React from "react";
import "../../assets/css/linebox.css";

const LineBox = ({ icon, subtitle, title, children, withBg = false }) => {
  return (
    <div className={withBg ? "line-box with-bg" : "line-box"}>
      <div className="icon-box">
        <div className="icon">{icon}</div>

        <div className="time">{subtitle}</div>
      </div>

      <div className="main-box">
        <div className="title">{title}</div>
        <div className="children">{children}</div>
      </div>
    </div>
  );
};

export default LineBox;
