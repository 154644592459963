import React, { useState, useEffect } from "react";
import DropdownList from "../../../components/form/DropdownList";
import PopupForm from "../../../components/ui/modal/PopupForm";
import {
  IoBookmarkOutline,
  IoPersonOutline,
  IoCallOutline,
  IoHomeOutline,
  IoMailOutline,
} from "react-icons/io5";
import InputField from "../../../components/form/InputField";
import { subjects } from "../../../utils/list";
import ErrorBox from "../../../components/ui/ErrorBox"; // Assurez-vous que le chemin est correct

const EditTeacherPopup = ({ onClose, teacherData }) => {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(""); // État pour le numéro de téléphone
  const [address, setAddress] = useState(""); // État pour l'adresse
  const [email, setEmail] = useState(""); // État pour l'e-mail
  const [selectedSubjects, setSelectedSubjects] = useState([]); // État pour les matières sélectionnées
  const [error, setError] = useState(""); // État pour gérer les messages d'erreur


  useEffect(() => {
    if (teacherData) {
      setFullName(teacherData.nom_complet);
      setPhoneNumber(teacherData.telephone);
      setAddress(teacherData.adresse);
      setEmail(teacherData.email);
      setSelectedSubjects(teacherData.matiere_enseigne || ["ami","jule"]); // Assurez-vous que teacherData.subjects est un tableau
    }
  }, [teacherData]);

  const handleSubjectChange = (index, value) => {
    const newSubjects = [...selectedSubjects];
    newSubjects[index] = value;
    setSelectedSubjects(newSubjects);
  };

  const addSubjectDropdown = () => {
    setSelectedSubjects([...selectedSubjects, ""]); // Ajoute un nouvel élément vide pour un nouveau DropdownList
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Vérification des champs requis
    if (
      !fullName ||
      !phoneNumber ||
      !address ||
      !email ||
      selectedSubjects.some((subject) => !subject)
    ) {
      setError("Veuillez remplir tous les champs requis.");
      return;
    }
    setError(""); // Réinitialiser l'erreur si tout est bon
    // Logique de soumission ici (par exemple, appel API pour mettre à jour les données du professeur)
  };

  return (
    <PopupForm
      onClose={onClose}
      title="Modifier un professeur"
      onSubmit={handleSubmit}
    >
      {error && <ErrorBox message={error} />}{" "}
      {/* Affichage du message d'erreur */}
      <InputField
        icon={<IoPersonOutline />}
        type="text"
        setValue={setFullName}
        placeholder="Sélectionnez un nom"
        value={fullName} // Pré-remplissage
      />
      <InputField
        icon={<IoCallOutline />}
        type="tel"
        setValue={setPhoneNumber}
        placeholder="Numéro de téléphone"
        value={phoneNumber} // Pré-remplissage
      />
      <InputField
        icon={<IoHomeOutline />}
        type="text"
        setValue={setAddress}
        placeholder="Adresse"
        value={address} // Pré-remplissage
      />
      <InputField
        icon={<IoMailOutline />}
        type="email"
        setValue={setEmail}
        placeholder="Adresse e-mail"
        value={email} // Pré-remplissage
      />
      <DropdownList
        icon={<IoBookmarkOutline />}
        options={subjects}
        selectedOptions={selectedSubjects}
        onChange={handleSubjectChange}
        onAdd={addSubjectDropdown}
      />
    </PopupForm>
  );
};

export default EditTeacherPopup;
