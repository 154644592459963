import React from "react";
import { Page} from "@react-pdf/renderer";
import styles from "./BulletinStyles";
import BulletinTitle from "./components/BulletinTitle";
import GradesTable from "./components/GradesTable";
import GradesSummary from "./components/GradesSummary";
import ObservationsAndMentions from "./components/ObservationsAndMentions";
import BulletinHeader from "./components/BulletinHeader";
import BulletinFooter from "./components/BulletinFooter";
import BulletinInfos from "./components/BulletinInfos";
import ClassGrade from "./components/ClassGrade";
import {getFrenchDateAndTime} from "../../../utils/dateConversion";


const Bulletin = ({bulletin, page}) => {

    const leftMentions = [
        {text: "Travail excellent", checked: bulletin.appreciation === "Travail excellent"},
        {text: "Satisfaisant doit continuer", checked: bulletin.appreciation === "Satisfaisant doit continuer"},
        {text: "Peut mieux faire", checked: bulletin.appreciation === "Peut mieux faire"},
        {text: "Insuffisant", checked: bulletin.appreciation === "Insuffisant"},
        {text: "Risque de redoubler", checked: bulletin.appreciation === "Risque de redoubler"},
        {text: "Risque l'exclusion", checked: bulletin.appreciation === "Risque l'exclusion"},
    ];

    const rightMentions = [
        {text: "Félicitations", checked: bulletin.mention === "Félicitations"},
        {text: "Encouragements", checked: bulletin.mention === "Encouragements"},
        {text: "Tableau d'honneur", checked: bulletin.mention === "Tableau d'honneur"},
        {text: "Passable", checked: bulletin.mention === "Passable"},
        {text: "Doit redoubler d'effort", checked: bulletin.mention === "Doit redoubler d'effort"},
        {text: "Avertissement", checked: bulletin.mention === "Avertissement"},
        {text: "Blâme", checked: bulletin.mention === "Blâme"},
    ];

    const observations = {
        title: "Observations du conseil des professeurs",
        content: bulletin.observation_professeur
    };

    return (
            <Page size="A4" style={styles.bulletinContainer} pageNumber={page}>
                <BulletinHeader schoolInfos={bulletin.schoolInfos}/>
                <BulletinInfos studentInfos={bulletin.studentInfos} classInfos={bulletin.classInfos}/>

                <BulletinTitle title={`BULLETIN DU ${bulletin.schoolInfos.semestre}`}/>

                <GradesTable grades={bulletin.gradesData} totals={bulletin.totals}/>

                <GradesSummary
                    gradesSummary={bulletin.gradesSummary}
                />
                <ClassGrade classGrade={bulletin.classGrade}/>

                <ObservationsAndMentions
                    leftMentions={leftMentions}
                    rightMentions={rightMentions}
                    observations={observations}
                />
                <BulletinFooter
                    qrCodeBulletinData={bulletin.verification.qr_code}
                    dateEdition={getFrenchDateAndTime()}
                />
            </Page>
    )
};

export default Bulletin;
