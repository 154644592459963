import React from "react";
import { IoPricetag, IoPricetagOutline, IoTimerOutline } from "react-icons/io5";
import LineBox from "../../components/ui/LineBox";

const SalaryBox = () => {
  return (
    <LineBox
      icon={<IoTimerOutline />}
      subtitle="23h et 35mn"
      title={"Achime Malick Ndiaye"}
    >
      {/* <span className="number">116 500 FCFA</span>
      <span className="icon">
        <IoPricetag />
      </span> */}
    </LineBox>
  );
};

export default SalaryBox;
