import React from "react";
import PopupBg from "./PopupBg";
import { motion } from "framer-motion";

const PopupForm = ({
  onClose,
  title,
  children,
  onSubmit,
  isLoading = false,
}) => {
  return (
    <PopupBg closeModal={onClose} opacity="transparent">
      <motion.div
        className="popup__form evaluation__add__form"
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <h2>{title}</h2>

        <div className="fields-container">{children}</div>

        <div className="buttons">
          <button className="cancel" onClick={onClose}>
            Annuler
          </button>
          <button className="send" disabled={isLoading} onClick={onSubmit}>
            {title.split(" ")[0]}
          </button>
        </div>
      </motion.div>
    </PopupBg>
  );
};

export default PopupForm;
