import React, { useState } from "react";
import { Header, Navbar, SubSidebar } from "../../layouts";
import "../../assets/css/pointage.css";
import UserWithActionBtn from "../../components/user/UserWithActionBtn";
import Presences from "./Presences";
import Salaires from "./Salaires";
import TeachersList from "./teachers-list/TeachersList";

const Teachers = () => {
  const [activePage, setActivePage] = useState("Professeurs");

  const togglePage = (page) => {
    setActivePage(page);
  };
  return (
    <>
      <Navbar />
      <Header title="Professeurs et pointage" />

      <div className="pointage main-fullwidth">
        <SubSidebar
          title="Comptabilité"
          // filterList={["Pointage", "Professeurs", "Heures"]}
          filterList={["Professeurs"]}
          filterFn={togglePage}
        />

          {activePage === "Professeurs" && <TeachersList />}
          {activePage === "Pointage" && <Presences />}
        {activePage === "Heures" && <Salaires />}
      </div>
    </>
  );
};

export default Teachers;
