import React, { useContext, useEffect, useState } from "react";
import { ClassContext } from "../contexts/ClassContext";
import { GetClasseQuery } from "../API/react_query/react_query";
import {
  generateClassName,
  compareClasses,
  formatNiveauNom,
} from "../utils/orderClasses";
import Loading from "./Loading";
import {
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoOptionsOutline,
} from "react-icons/io5";
import AddClassPopup from "./AddClassPopup";
import { Link, useNavigate } from "react-router-dom";

const ClassesList = ({ refetchFn }) => {
  const [isAddOpen, setIsAddOpen] = useState(false);

  const { data: data, refetch: retcheClasse, isLoading } = GetClasseQuery();
  const { currentClass, currentClassName, changeClass } =
    useContext(ClassContext);
  const [activeClassId, setActiveClassId] = useState(currentClass);
  const [classes, setClasses] = useState([]);
  const [opened, setOpened] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (data?.classes) {
      const classesWithFullName = data.classes.map((item) => ({
        id: item?.id,
        name: generateClassName(item),
        niveau: item?.niveau?.nom,
      }));

      // Trier les classes
      const sortedClasses = classesWithFullName.sort(compareClasses);

      // Formater les noms des niveaux après le tri
      const formattedClasses = sortedClasses.map((item) => ({
        ...item,
        name:
          formatNiveauNom(item?.niveau) +
          item.name.substring(item?.niveau.length),
      }));

      setClasses(formattedClasses);
    }
  }, [data]);

  if (isLoading) {
    return <Loading size="medium" />;
  }

  const toggleAddPopup = () => {
    setIsAddOpen((prev) => !prev);
    retcheClasse();
  };

  const handleClassClick = (id, className) => {
    setActiveClassId(id);
    changeClass(id, className);
    setOpened(false);
    // refetchFn(id);
  };
  const handleClassDoubleClick = (id, className) => {
    handleClassClick(id, className);
    navigate("/classe");
  };

  const toggleOpen = () => {
    setOpened((prev) => !prev);
  };

  return (
    <>
      <article className={opened ? "classes opened" : "classes"}>
        <div className="slider">
          <div className="classes__header">
            <span className="text">Liste des classes</span>
            <Link to={"/classe"} className="icon">
              <IoOptionsOutline />
            </Link>
          </div>
          {classes.length > 0 ? (
            classes.map(({ id, name }) => (
              <div
                key={id}
                className={`classe ${activeClassId === id ? "active" : ""}`}
                onClick={() => handleClassClick(id, name)}
                onDoubleClick={() => handleClassDoubleClick(id, name)}
              >
                {name}
              </div>
            ))
          ) : (
            <div className="not-classe">
              Aucune classe. Appuyez sur le bouton{" "}
              <span className="plus">"+"</span> pour ajouter une classe
            </div>
          )}
          <div
            className="classe add-btn"
            title="Ajouter une classe"
            onClick={toggleAddPopup}
          >
            +
          </div>
        </div>
      </article>

      <div className={opened ? "mobile-tab opened" : "mobile-tab"}>
        <button
          className="add-class"
          title="Ajouter une classe"
          onClick={toggleAddPopup}
        >
          <span className="icon">+</span>
          <span className="text">Nouvelle classe</span>
        </button>

        <Link to="/classe" className="edit-class">
          <IoOptionsOutline />
        </Link>

        <div className="current-class">
          {currentClass ? currentClassName : "Aucune classe"}
        </div>

        <button className="dropdown" onClick={toggleOpen}>
          <span className="icon opened">
            <IoChevronUpOutline />
          </span>
          <span className="icon closed">+</span>
        </button>
      </div>

      {isAddOpen && <AddClassPopup onClose={toggleAddPopup} />}
    </>
  );
};

export default ClassesList;
