import React from 'react'
import { IoNotificationsOutline, IoPencil, IoTrashOutline } from 'react-icons/io5'

const ShowFullNotif = () => {
  return (
    <div className='Show__full__notif'>
      <div className="head">
         <div className="icon"><IoNotificationsOutline /></div>
         <div className="date">Lundi, 12 juillet - 12:54</div>
      </div>

        <h1>Réunion des surveillqnts aujourd'hui à 13h</h1>

        <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium at odit aspernatur earum, labore fugiat dicta exercitationem quasi. Iusto corrupti eligendi laboriosam ipsam tempore sed perspiciatis labore ad eveniet obcaecati debitis nostrum consectetur quibusdam modi, tenetur, iste molestias autem totam.
        </p>

        <div className="options">
          <div className="option">
            <IoTrashOutline />
          </div>
          <div className="option">
            <IoPencil />
          </div>
        </div>
    </div>
  )
}

export default ShowFullNotif