//import { useNavigate } from "react-router-dom";
import { accountService } from "../service/account.service";
import { uploadToken, uploadTokenDEL } from "./upload";

const token = accountService.getToken();

async function deletEvaluation(id, onClose) {
  const endpoint = "evaluations/" + id;
  const reponse = await uploadTokenDEL(endpoint);

  if (reponse.ok == true) {
     console.log("Evaluation ajoutée avec succès");
    onClose();
  } else {
    const errorDetails = await reponse.json();
    console.error("Erreur serveur:", reponse.status, errorDetails);
  }
}

export { deletEvaluation };
