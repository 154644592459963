import { StyleSheet, Font } from "@react-pdf/renderer";

// Importation de la police Open Sans depuis Google Fonts
Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2',
      fontWeight: 'normal',
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v29/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu0SC55K5gw.woff2',
      fontWeight: 'bold',
    },
  ],
});

export const styles = StyleSheet.create({
  bulletinContainer: {
    // fontFamily: "Open Sans",
    backgroundColor: "#fff",
    color: "#000",
    fontSize: 9,
    width: "100%",
    height: "100%",
    padding: 30,
    paddingBottom: 10,
    // backgroundColor: "#111",
    // color: "#fff",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 2,
    borderBottom: "5 solid rgba(0, 0, 0, 0.6)",
    marginBottom: 3,
  },
  logo: {
    width: 80,
    height: 'auto',
  },
  infosEcole: {
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  nomEcole: {
    fontWeight: 600,
    fontSize: 12,
  },
  moreInfos: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 10,
  },
  infoBox: {
    padding: "5 10",
    border: "1 solid rgba(0, 0, 0, 0.5)",
    width: "49%",
  },
  line: {
    lineHeight: 1.5,
    flexDirection: "row",
  },
  title: {
    opacity: 0.7,
  },
  nomBulletin: {
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 'bold',
    color: '#000',
    height: 18,
    backgroundColor: "lightblue",
    fontSize: 18,
    marginTop: 15,
    marginBottom: 20,
  },
  tableContainer: {
    marginBottom: 15,
    fontSize: 9,
  },
  tableRow: {
    flexDirection: "row",
    marginBottom: 4,
  },
  tableCell: {
    padding: "0 5",
    textAlign: "center",
    flex: 1,
  },
  tableHead: {
    // backgroundColor: "lightgray",
    fontWeight: 600,
  },
  tableCellDisciplines: {
    textAlign: "left",
    flex: 6,
  },
  tableCellAppreciations: {
    textAlign: "left",
    flex: 3,
  },
  moyenneContainer: {
    backgroundColor: "lightgray",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 25,
    padding: "0 10",
    marginBottom: 15,
  },
  detailsTable: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  detailsTableColumn: {
    flex: 1,
    marginRight: 10,
  },
  detailsTableItem: {
    border: "1 solid #000",
    padding: "0 10",
    height: 20,
    justifyContent: "center",
  },
  apprConseil: {
    flex: 1,
  },
  cage: {
    height: 80,
  },
  signature: {
    border: "none",
    alignSelf: "flex-end",
    paddingRight: 40,
    fontWeight: "bold",
    textDecoration: "underline",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  qrCode: {
    height: 90,
    width: 90,
    backgroundColor: "silver",
    marginBottom: 5,
  },
  warning: {
    fontSize: 9,
    marginBottom: 5,
  },
  date: {
    width: "100%",
    fontSize: 9,
    backgroundColor: "gray",
    color: "#fff",
    textAlign: "right",
    padding: "2 10",
  },
});

export default styles;
