import { accountService } from "../service/account.service";

const url = "https://educaly.net/api/";

//const headers ={'Authorization': 'Bearer {token}'}

async function upload(endpoint, body, headers = {}) {
  try {
    const reponse = await fetch(url + endpoint, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    ///const resultat = await reponse.json();

    //// console.log("Réussite :", reponse);
    return reponse;
  } catch (erreur) {
    // console.error("Erreur :", erreur);
  }
}

async function uploadToken(endpoint, bodyy) {
  const token = accountService.getToken();
  try {
    const reponse = await fetch(url + endpoint, {
      method: "POST",
      body: JSON.stringify(bodyy),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return reponse;
  } catch (erreur) {
    console.error("Erreur :", erreur);
  }
}

async function uploadTokenPUT(endpoint, bodyy) {
  const token = accountService.getToken();
  try {
    const reponse = await fetch(url + endpoint, {
      method: "PUT",
      body: JSON.stringify(bodyy),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return reponse;
  } catch (erreur) {
    console.error("Erreur :", erreur);
  }
}

async function uploadTokenDEL(endpoint) {
  const token = accountService.getToken();
  try {
    const reponse = await fetch(url + endpoint, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return reponse;
  } catch (erreur) {
    console.error("Erreur :", erreur);
  }
}

async function load(endpoint, headers = {}) {
  try {
    const reponse = await fetch(url + endpoint, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    ///const resultat = await reponse.json();

    //// console.log("Réussite :", reponse);
    return reponse;
  } catch (erreur) {
    //console.error("Erreur :", erreur);
  }
}

export { upload, load, uploadToken, uploadTokenPUT, uploadTokenDEL };
