import React from "react";
import {
  IoAlarmOutline,
  IoCalendarClearOutline,
  IoCalendarOutline,
  IoTimeOutline,
} from "react-icons/io5";
import LineBox from "../../components/ui/LineBox";

const DayPlanBox = ({
  onStop,
  onReport,
  heur_debut,
  heur_fin,
  matier,
  prof,
  commands = false,
  isEvaluation = false,
  id_modif,
  evaluation,
}) => {
  const donnee = {
    id_modif: id_modif,
    heur_debut: heur_debut,
    heur_fin: heur_fin,
  };

  return (
    <LineBox
      icon={<IoTimeOutline />}
      subtitle={heur_debut + " - " + heur_fin}
      title={evaluation?.nom ? evaluation.nom +  ", " + matier : matier}
      withBg={false}
    >
      {commands && (
        <div onClick={()=>onStop(evaluation,matier)} >
          <button style={{ display: 'flex', alignItems: 'center', gap: 10, backgroundColor: '#FF4500', border: "none", borderRadius: 10, }}>
            {/*<div className="icon" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>*/}
            {/*  <IoCalendarClearOutline/>*/}
            {/*</div>*/}
            <p style={{paddingInline: 10, textTransform: 'uppercase', color: '#fff'}}>supprimer</p>
          </button>
        </div>
      )}

      {/*{commands && (*/}
      {/*  <div className="options">*/}
      {/*    <button className="report" onClick={(e) => onReport(donnee)}>*/}
      {/*      <div className="icon">*/}
      {/*        <IoCalendarOutline />*/}
      {/*      </div>*/}
      {/*      Reporter*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </LineBox>
  );
};

export default DayPlanBox;
