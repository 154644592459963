import React from "react";
import UserBox from "../../components/user/UserBox";
import UserWithActionBtn from "../../components/user/UserWithActionBtn";

const MissingBox = ({ user, matier, heur_debut, heur_fin, justifyFn }) => {
  return (
    <UserWithActionBtn
      user={user}
      subtitle={
        <span>
          <b>{matier}</b> {heur_debut} - {heur_fin}
        </span>
      }
      btnText="Justifier"
      btnFn={() => justifyFn(user.id)}
    />
  );
};

export default MissingBox;
