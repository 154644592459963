// components/ProtectedRoute.js
import React from "react";
import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RolesContext } from "./RolesContext";

const ProtectedRoute = () => {
  const { hasAccess } = useContext(RolesContext);
  const location = useLocation();

  // Vérifie si l'utilisateur a accès à l'emplacement actuel
  return hasAccess(location.pathname) ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
