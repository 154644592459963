import React, { useContext } from "react";
import { IoCameraOutline } from "react-icons/io5";
import { ClassContext } from "../../contexts/ClassContext";

const SchoolDetails = () => {
  const { user } = useContext(ClassContext);

  return (
    <div className="school-details">
      <div className="logo">
        <IoCameraOutline />
      </div>

      <div className="text">
        <div className="school-name">{user?.data?.ecole?.nom}</div>
        <div className="school-address">{user?.data?.ecole?.adresse}</div>
      </div>
    </div>
  );
};

export default SchoolDetails;
