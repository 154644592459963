import React from "react";
import {
  IoNotificationsOutline,
  IoSend,
  IoText,
  IoTime,
  IoTimeOutline,
} from "react-icons/io5";
import PopupBg from "../../components/ui/modal/PopupBg";

const StoppingCoursesPopup = ({ onClose }) => {
  return (
    <PopupBg closeModal={onClose}>
      <div className="popup__form stopping__courses__popup">
        <h2>Annuler le cours</h2>

        <p className="input__box">
          <div className="icon">
            <IoNotificationsOutline />
          </div>
          <input
            type="text"
            className="message"
            placeholder="Ecrire les motifs de l
                l'annulation..."
          />
        </p>

        <div className="buttons">
          <button className="cancel" onClick={onClose}>
            Fermer
          </button>
          <button className="send">
            Envoyer
            <span className="icon">
              <IoSend />
            </span>
          </button>
        </div>
      </div>
    </PopupBg>
  );
};

export default StoppingCoursesPopup;
