import React from "react";
import PopupForm from "./PopupForm";
import { IoWarningOutline } from "react-icons/io5";
import { deletMatiere } from "../../../API/fonctions/deletMatiere";

const ConfirmDeletePopup = ({ onClose, elementId, text, title }) => {
  const handleConfirmDelete = () => {
    // console.log(`Deleting subject with ID: ${elementId}`);
    // Here you would typically call your delete function
    // For now, we'll just close the popup
    deletMatiere(elementId, onClose);
    //onClose();
  };

  return (
    <PopupForm onClose={onClose} title={title} onSubmit={handleConfirmDelete}>
      <div className="confirm-delete-content">
        <IoWarningOutline className="warning-icon" />
        <p>{text}</p>
      </div>
    </PopupForm>
  );
};

export default ConfirmDeletePopup;
