import React, { useState, useContext, useMemo } from "react";
import {
  IoBookmarkOutline,
  IoCalendarOutline,
  IoDocumentTextOutline,
  IoFolderOpenOutline,
  IoTextOutline,
  IoTimerOutline,
} from "react-icons/io5";
import { ClassContext } from "../../contexts/ClassContext";
import { GetMatClasseQuery, GetSemes } from "../../API/react_query/react_query";
import { addEvaluation } from "../../API/fonctions/addEvaluation";
import { getDteforAPI } from "../../utils/fonctions";
import Loading from "../../layouts/Loading";
import PopupForm from "../../components/ui/modal/PopupForm";
import DropdownList from "../../components/form/DropdownList";
import InputField from "../../components/form/InputField";
import TimeRangeInput from "../../components/form/TimeRangeInput";
import ErrorBox from "../../components/ui/ErrorBox";

const EvaluationAddForm = ({ onClose }) => {
  const { currentClass, anneeScol } = useContext(ClassContext);
  const [messageErrore, setMessageErrore] = useState();
  const [process, setProcess] = useState();
  const { data: subjects, isLoading: isLoadingSubjects } = GetMatClasseQuery(
    currentClass,
    anneeScol
  );
  const { data: semesters, isLoading: isLoadingSemesters } =
    GetSemes(anneeScol);

  const [formData, setFormData] = useState({
    subjectId: "",
    evaluationType: "Devoir 1",
    semesterId: "",
    date: getDteforAPI(),
    timeRange: { start: "08:00", end: "11:00" },
    description: "",
  });
  const [evalTimeRange, setEvalTimeRange] = useState({
    start: "08:00",
    end: "11:00",
  });

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = (e) => {
    const {
      subjectId,
      semesterId,
      evaluationType,
      date,
      timeRange,
      description,
    } = formData;
    if (!subjectId || !semesterId) return;

    const evaluationData = {
      nom: evaluationType,
      description: description || "une evaluation",
      heure_debut: evalTimeRange.start,
      heure_fin: evalTimeRange.end,
      date,
      is_exam: "1",
      matiere_id: parseInt(subjectId),
      semestre_id: parseInt(semesterId),
      année_scolaire_id: anneeScol,
      classe_id: currentClass,
    };

    addEvaluation(evaluationData, onClose, setMessageErrore, setProcess);
  };

  const subjectItems = useMemo(
    () =>
      subjects?.map((subject) => ({
        id: subject.id.toString(),
        content: subject.libelle,
      })) || [],
    [subjects]
  );

  const semesterItems = useMemo(
    () =>
      semesters?.map((semester) => ({
        id: semester.id.toString(),
        content: semester.nom,
      })) || [],
    [semesters]
  );

  const evaluationTypes = [
    { id: "Devoir 1", content: "Devoir 1" },
    { id: "Devoir 2", content: "Devoir 2" },
    { id: "Composition", content: "Composition" },
    { id: "Conduite", content: "Conduite" },
  ];

  if (isLoadingSubjects || isLoadingSemesters) {
    return <Loading size="small" />;
  }

  return (
    <PopupForm
      onClose={onClose}
      title="Plannifier une évaluation"
      onSubmit={handleSubmit}
      isLoading={process}
    >
      {messageErrore && <ErrorBox message={messageErrore} />}
      <DropdownList
        icon={<IoBookmarkOutline />}
        items={subjectItems}
        value={formData.subjectId}
        setValue={(id) => handleInputChange("subjectId", id)}
        placeholder="Sélectionner une matière"
      />
      <DropdownList
        icon={<IoDocumentTextOutline />}
        items={evaluationTypes}
        value={formData.evaluationType}
        setValue={(type) => handleInputChange("evaluationType", type)}
        placeholder="Type d'évaluation"
      />
      <DropdownList
        icon={<IoFolderOpenOutline />}
        items={semesterItems}
        value={formData.semesterId}
        setValue={(id) => handleInputChange("semesterId", id)}
        placeholder="Sélectionner un semestre"
      />
      <InputField
        icon={<IoCalendarOutline />}
        type="date"
        value={formData.date}
        setValue={(date) => handleInputChange("date", date)}
        name="date"
      />
      <TimeRangeInput
        icon={<IoTimerOutline />}
        value={evalTimeRange}
        onChange={setEvalTimeRange}
      />
      <InputField
        icon={<IoTextOutline />}
        type="text"
        placeholder="Descriptif ... (facultatif)"
        value={formData.description}
        setValue={(desc) => handleInputChange("description", desc)}
        name="description"
      />
    </PopupForm>
  );
};

export default EvaluationAddForm;
