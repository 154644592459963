import React, { useContext } from "react";
import welcomeImg from "../../assets/images/welcome.png";
import { ClassContext } from "../../contexts/ClassContext";

const WelcomeCard = () => {
  const { user } = useContext(ClassContext);

  return (
    <div className="welcome-card">
      <div className="image">
        <img src={welcomeImg} alt="Image de bienvenue" />
      </div>

      <div className="text">
        <div className="title">
          Heureux de vous voir <br />
          <span className="name">{user?.data?.directeur?.nom_complet ?? user?.data?.nom ?? user?.data?.caissier?.nom}</span>
        </div>
        <div className="description">
          Bienvenue sur CALY, votre solution complète pour une gestion scolaire
          fluide, connectant écoles, parents et professeurs pour améliorer la
          communication et soutenir l'excellence éducative.
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;
