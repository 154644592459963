import React from "react";
import "../assets/css/loading.css";

const Loading = ({ size = "normal", text = null, progress = null }) => {
  return (
    <div className={"loading-container " + size}>
      {text && <p className={"text"}>{text}</p>}
      {progress && <p className={"text"}>{progress}%</p>}
      <div className="loading-bar">
        <div className="loading-indicator"></div>
      </div>
    </div>
  );
};
export default Loading;
