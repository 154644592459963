import React from 'react';
import {StyleSheet, Text, View} from '@react-pdf/renderer';

// Composant pour afficher les observations et mentions
const ObservationsAndMentions = ({
                                     leftMentions,
                                     rightMentions,
                                     observations,
                                     signatureText = "Le Chef d'établissement"
                                 }) => (
    <View style={styles.container}>
        {/*<View style={styles.leftSection}>*/}
            {/* Table des mentions de gauche */}
            <View style={styles.mentionsTable}>
                {leftMentions.map((mention, index) => (
                    <View key={`left-${index}`} style={styles.tableRow}>
                        <Text style={styles.mentionCell}>{mention.text}</Text>
                        <Text style={styles.checkboxCell}>{mention.checked ? 'x' : ''}</Text>
                    </View>
                ))}
            </View>

            {/* Table des mentions de droite */}
            <View style={styles.mentionsTable}>
                {rightMentions.map((mention, index) => (
                    <View key={`right-${index}`} style={styles.tableRow}>
                        <Text style={styles.mentionCell}>{mention.text}</Text>
                        <Text style={styles.checkboxCell}>{mention.checked ? 'x' : ''}</Text>
                    </View>
                ))}
            </View>
        {/*</View>*/}

        <View style={styles.rightSection}>
            {/* Table des observations */}
            <View style={styles.observationsTable}>
                <View style={styles.observationHeader}>
                    <Text>{observations.title}</Text>
                </View>
                <View style={styles.observationContent}>
                    <Text>{observations.content}</Text>
                </View>
            </View>

            {/* Signature */}
            <Text style={styles.signature}>{signatureText}</Text>
        </View>
    </View>
);

// Styles pour la section d'observations et mentions
const styles = StyleSheet.create({
    container: {
        marginTop: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    leftSection: {
        width: '60%',
        flexDirection: 'row',
    },
    rightSection: {
        width: '38%',
    },
    mentionsTable: {
        width: '50%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        marginRight: 3
    },
    observationsTable: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomColor: '#000',
        // minHeight: 25,
    },
    mentionCell: {
        width: '80%',
        borderRightWidth: 1,
        borderRightColor: '#000',
        paddingHorizontal: 4,
        justifyContent: 'center',
        paddingVertical: 3
    },
    checkboxCell: {
        width: '20%',
        textAlign: 'center',
        paddingHorizontal: 4,
        justifyContent: 'center',
        paddingVertical: 3
    },
    observationHeader: {
        backgroundColor: '#f2f2f2',
        padding: 4,
        borderBottomWidth: 1,
        borderBottomColor: '#000',
    },
    observationContent: {
        padding: 8,
        minHeight: 60,
    },
    signature: {
        marginTop: 40,
        textAlign: 'right',
        paddingRight: 20,
        fontWeight: 'bold',
        textDecoration: 'underline',
        fontSize: 11,
    }
});

export default ObservationsAndMentions;
